import React, { useEffect, useState } from "react";
import { Headline } from "../headline/headline.js";
import { Adminsidebar } from "../adminsidebar.js";
import { Footer } from "../footer/footer.js";
import { useNavigate, useParams } from "react-router-dom";
import { Parent_notification } from "../../pages/parent/popup/Parent_notification.js";
import { parent_get_id, user_get_id } from "../../service/api.js";
import Cookies from "js-cookie";
import { profile_get_id, students_get, promo_get_http } from "../../service/client_api.js";

export const AdminLayout = ({ children, headline }) => {

  const { id } = useParams()

  const [fName, setFName] = useState("")
  const [LName, setLName] = useState("")
  const [students, setStudents] = useState([])
  const [PromotionData, setPromotionData] = useState([])
  useEffect(() => {
    getuser()
  }, [])

  const student_get = async () => {
    const res = await students_get()
    console.log("students get", res)
    setStudents(res?.data?.data);
    localStorage.setItem("students", res?.data?.data?.length)

  }
  useEffect(() => {
    student_get();
  }, []);
  const getuser = async () => {
    const res = await profile_get_id()
    console.log("res", res)
    if (res?.data) {
      if (res.data.data.FirstName) {
        setFName(res.data.data.FirstName)

      }
      if (res.data.data.LastName) {
        setLName(res.data.data.LastName)

      }
    }

    const res2 = await promo_get_http("Parent");
    if (res2?.data) {
      if (res2?.data?.Data?.length == 1) {
        setPromotionData(res2.data.Data[0])
      }
    }
  }
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  const [isStudentMenuOpen, setStudentMenuOpen] = useState(false);


  useEffect(() => {
    const handleResize = () => {
      // Check if screen width is less than or equal to 767
      if (window.innerWidth <= 980) {
        // setMobileMenuOpen(true);
      } else {
        setMobileMenuOpen(false);
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Call handleResize initially to set the initial state based on window width
    handleResize();

    // Cleanup by removing event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures that this effect runs only once after the initial render




  const toggleStudentMenu = () => {
    setStudentMenuOpen(!isStudentMenuOpen);
  };
  const [plannerCount, setPlannerCount] = useState(() => {
    const storedPlannerCount = localStorage.getItem("plannerCount");
    return storedPlannerCount || '0';
  });
  useEffect(() => {
    const updatePlannerCount = () => {
      const storedPlannerCount = localStorage.getItem("plannerCount");
      if (storedPlannerCount) {
        setPlannerCount(storedPlannerCount);
        console.log("plannerCount", storedPlannerCount);
      }
    };

    window.addEventListener('storage', updatePlannerCount);

    return () => {
      window.removeEventListener('storage', updatePlannerCount);
    };
  }, []);

  console.log("plannerCount", plannerCount)

  useEffect(() => {
    if (localStorage.getItem("students") === "0") {
      localStorage.removeItem("selectedButtons")
    }
  }, [])




  const _nav = [

    {
      name: "Dashboard",
      to: "/parent_dashboard",
      icon: "/img/sidebar/1.svg",
    },
    {
      name: "Student mode",
      to: "/",
      //  onClick: handleOneStudent,
      // icon: "/img/sidebar/parent/9.svg",
      icon: "/img/student_icon.svg",
      onIcon: "/img/sidebar/onIcon.svg",
      children: students?.map((student) => ({
        name: `${student.FirstName} ${student.LastName}`,
        FirstName: student.FirstName,
        LastName: student.LastName,
        UserID: student.UserID,
        AccountType: "Student",

        to: `/student_enrolled_courses`,
      })),

    },
    {
      name: "Courses ",
      to: "/parent_course_grid",
      icon: "/img/sidebar/2.svg",
    },
    {
      name: "Planner",
      to: "/parent_planner",
      tag: plannerCount,
      icon: "/img/sidebar/parent/3.svg",
    },
    {
      name: "Reports",
      to: "/parent_report",
      icon: "/img/sidebar/4.svg",
    },
    {
      hr: true,
    },
    {
      name: "Profile",
      to: "/parent_account",
      icon: "/img/sidebar/6.svg",
    },
    {
      name: "Membership",
      to: "/parent_membership",
      icon: "/img/sidebar/parent/6.svg",
    },
    {
      name: "Billing & invoice",
      to: "/parent_billing",
      icon: "/img/sidebar/parent/7.svg",
    },
    {
      name: "Support",
      and: [`/answer/${id}`, "/contact", "/contact_learning_advisor", "/contact_representative", "/leave_feedback"],
      to: "/parent_help",
      icon: "/img/sidebar/parent/8.svg",
    },
    {
      name: "Sign out",
      to: "Js:",
      icon: "/img/sidebar/8.svg",
    },
  ];
  return (
    <>
      {headline === true && (
        <Headline className={`yellow-bg ${isMobileMenuOpen ? "d-none" : ""
          }`} >{PromotionData?.PromotionTitle} ✅</Headline>
      )}
      <div
        className={`admin_layout_wrapper container-fluid ${isMobileMenuOpen ? "mobile-menu-open" : ""
          }`}
      >
        <header className="admin_layout_head">
          <>
            {isMobileMenuOpen ? (
              <div className="mobile_menu_call_btn" onClick={toggleMobileMenu}>
                <img src="/img/Close1.svg" />
              </div>
            ) : (
              <div className="mobile_menu_call_btn" onClick={toggleMobileMenu}>
                <img src="/img/Hamburger.png" />
              </div>
            )}

            <div className="admin_layout_logo">
              <img src="/img/platform_logo.svg" />
            </div>
            <div className="admin_layout_head2">
              <div className="admin_layout_pagename"></div>
              <div className="admin_layout_icons">
                <Parent_notification />
                <div className="admin_layout_user">{fName && (
                  LName
                    ? fName.charAt(0).toUpperCase() + LName.charAt(0).toUpperCase()
                    : fName.charAt(0).toUpperCase()
                )}</div>
              </div>
            </div>
          </>
        </header>
        <div className="addminFull"></div>

        <div className="admin_layout_main">
          <aside className="admin_layout_sidebar">
            <Adminsidebar
              item={_nav}
              isMobileMenuOpen={isMobileMenuOpen}
              toggleMobileMenu={toggleMobileMenu}
              isStudentMenuOpen={isStudentMenuOpen}
              toggleStudentMenu={toggleStudentMenu}




            />
          </aside>
          <div className="admin_layout_body">
            <div className="admin_layout_breadcrumbs">
              <p></p>
            </div>
            {children}
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
};
