import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import dotenv from 'react-dotenv';
import { ToastContainer, toast } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';

dotenv?.config();
const client_id = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID;

// require('dotenv').config();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={client_id}> <React.StrictMode>
  <ToastContainer/>
  <App />
</React.StrictMode>
</GoogleOAuthProvider>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
