import React, {lazy, Suspense, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Navigate } from 'react-router-dom';
import { Login } from "./pages/login.js";
import Cookies from "js-cookie";
import './App.css';
import "./assets/admin/css/addCourse.css";
import "../src/components/course-grade-view/style.css";
import "../src/components/tooltip/customtooltip.css";
import "../src/components/user_access/user_access.css";
import "./assets/admin/css/dashboard.css";
import "./assets/admin/css/style.css";
import "./assets/company/css/style.css";
import "./assets/parent/css/style.css";
import "./assets/student/style.css"
import "./pages/student/parts/loaders/css/loader.css";
import "./pages/student/parts/loaders/css/eventloader.css";
import "./pages/student/parts/loaders/css/courseDetailloader.css";
import Company_billing from "./pages/company/Company_Billing.js";
import Company_member_add from "./pages/company/Company_member_add.js";
import CMS_Email_form_Update from "./pages/admin/cms_email_form_update.js";
import { Student_mode } from "./pages/parent/student_mode.js";



// Import components lazily

const Admin_add_lesson2 = lazy(() => import("././pages/admin/admin_add_lesson2.js"));
const Admin_add_course2 = lazy(() => import("./pages/admin/admin_add_course2.js"));
// const CMS_Email_form = lazy(() => import("./pages/admin/cms_email_form.js"));
const Admin_course_grade_view = lazy(() => import("./pages/admin/admin_course_grade_view.js"));
const Admin_course_list_view = lazy(() => import("./pages/admin/admin_course_list_view.js"));
const Admin_Course_detail = lazy(() => import("./pages/admin/admin_course_preview.js"));
const Users_list_table = lazy(() => import("./pages/admin/user_list_table.js"));
const User_parent_form = lazy(() => import("./pages/admin/user_parent_form.js"));
const Admin_user_list = lazy(() => import("./pages/admin/report.js"));
const Admin_help = lazy(() => import("./pages/admin/help.js"));
const Parent_help = lazy(() => import("./pages/parent/Parent_help.js"));
const User_student_form = lazy(() => import("./pages/admin/user_student_form.js"));
const Admin_competition_list_table = lazy(() => import("./pages/admin/admin_competition_list_table.js"));
const Admin_competition_form = lazy(() => import("./pages/admin/admin_competition_form.js"));
const Help_preview = lazy(() => import("./pages/admin/help_preview.js"));
const Admin_promo = lazy(() => import("./pages/admin/admin_promo.js"));
const Admin_promo_ = lazy(() => import("./pages/admin/admin_promo_.js"));
const Admin_help_add = lazy(() => import("./pages/admin/admin_help_add.js"));
const Student_enrolled_courses = lazy(() => import("./pages/student/student_enrolled_courses.js"));
const Student_achievement = lazy(() => import("./pages/student/student_achivment.js"));
const Student_certificate = lazy(() => import("./pages/student/student_certificate.js"));
const Student_portfolio = lazy(() => import("./pages/student/student_portfolio.js"));
const Student_competition = lazy(() => import("./pages/student/Student_competition.js"));
const Dashboard = lazy(() => import("./pages/admin/dashboard.js"));
const Parent_dashboard = lazy(() => import("./pages/parent/parent_dashboard.js"));
const Parent_planner = lazy(() => import("./pages/parent/parent_planner.js"));
const Course_enrolled = lazy(() => import("./pages/parent/course_enrolled.js"));
const Parent_Course_grid = lazy(() => import("./pages/parent/Parent_Course_grid.js"));
// const Parent_course_list = lazy(() => import("./pages/parent/Parent_course_list.js"));
const Parent_report = lazy(() => import("./pages/parent/parent_report.js"));
const Parent_membership_exit = lazy(() => import("./pages/parent/Parent_membership_exit.js"));
// const Admin_pathway_grid = lazy(() => import("./pages/admin/admin_pathway_grid.js"));
// const Admin_add_pathway = lazy(() => import("./pages/admin/admin_add_pathway.js"));
const Self_learning_2 = lazy(() => import("./pages/parent/Self_learning_2.js"));
const Self_learning = lazy(() => import("./pages/parent/Self_learning.js"));
const Structured_learning_2 = lazy(() => import("./pages/parent/structured_learning_2.js"));
const Course_list_loader = lazy(() => import("./components/loader/Course_list_loader.js"));
const Help_answer_page = lazy(() => import("./pages/parent/Help_answer_page.js"));
const Contact_us = lazy(() => import("./pages/parent/contact_us.js"));
const Contact_learning_advisor = lazy(() => import("./pages/parent/contact_learning_advisor.js"));
const Contact_representative = lazy(() => import("./pages/parent/contact_representative.js"));
const Leave_feedback = lazy(() => import("./pages/parent/leave_feedback.js"));
const Thanks_for_Contact = lazy(() => import("./pages/parent/contact_thanks.js"));
const Parent_membership_form = lazy(() => import("./pages/parent/parent_membership_form.js"));
const Parent_profile_ready = lazy(() => import("./pages/parent/parent_profile_ready.js"));
const Parent_profile = lazy(() => import("./pages/parent/parent_profile.js"));
const Parent_student_profile = lazy(() => import("./pages/parent/parent_student_profile.js"));
const Parent_membership = lazy(() => import("./pages/parent/parent_membership.js"));
const Parent_membership_invite = lazy(() => import("./pages/parent/parent_membership_invite.js"));
const Company_dashboard = lazy(() => import("./pages/company/Company_course.js"));
const Course_catalog = lazy(() => import("./pages/company/Course_catalog.js"));
const Company_account = lazy(() => import("./pages/company/Company_account.js"));
const Company_account_edit = lazy(() => import("./pages/company/Company_account_edit.js"));
const Company_profile_detail = lazy(() => import("./pages/company/Company_profile_detail.js"));
const Company_profile_list_table = lazy(() => import("./pages/company/Company_profile_list_table.js"));
const Company_invite = lazy(() => import("./pages/company/Company_invite.js"));
const Add_profile = lazy(() => import("./pages/admin/Add_profile.js"));
const Student_course_interest = lazy(() => import("./pages/student/Student_course_interest.js"));
const Student_Course_detail = lazy(() => import("./pages/student/student_course_detail.js"));
const User_access = lazy(() => import("./components/user_access/User_access.js"));
const Add_user = lazy(() => import("./pages/admin/Add_user.js"));
const Company_Reapproval = lazy(() => import("./pages/admin/company_re-approval.js"));
const Parent_portfolio = lazy(() => import("./pages/parent/parent_portfolio.js"));
const Create_student_account = lazy(() => import("./pages/admin/Create_student_account.js"));
const Add_profile_student = lazy(() => import("./pages/admin/Add_profile_student.js"));
const Company_membership = lazy(() => import("./pages/company/Membership.js"));
const Company_Membership_exit = lazy(() => import("./pages/company/Membership_exit.js"));
const Company_Membership_form = lazy(() => import("./pages/company/Membership_form.js"));
const Company_help = lazy(() => import("./pages/company/Help.js"));
const Company_contact_us = lazy(() => import("./pages/company/Company_contact.js"));
const Company_contact_learning = lazy(() => import("./pages/company/Company_learning.js"));
const Company_Contact_representative = lazy(() => import("./pages/company/Company_contactrepresentative.js"));
const Company_Leave_feedback = lazy(() => import("./pages/company/Company_leave_feedback.js"));
const Company_Thanks_for_Contact = lazy(() => import("./pages/company/Company_contact_thanks.js"));
const Company_Help_answer_page = lazy(() => import("./pages/company/Company_Help_answer_page.js"));
const Parent_account = lazy(() => import("./pages/parent/parent_account.js"));
const EnrolledCourseLoader = lazy(() => import("./pages/student/parts/loaders/EnrolledCourse.js"));
const CertificateLoader = lazy(() => import("./pages/student/parts/loaders/CertificateLoader.js"));
const CourseDetailLoader = lazy(() => import("./pages/student/parts/loaders/CourseDetailLoader.js"));
const PortfolioLoader = lazy(() => import("./pages/student/parts/loaders/PortfolioLoader.js"));
const AchievementLoader = lazy(() => import("./pages/student/parts/loaders/AchievementLoader.js"));
const EventLoader = lazy(() => import("./pages/student/parts/loaders/EventLoader.js"));
const ListLoader = lazy(() => import("./pages/admin/loaders/ListLoader.js"));
const AccountsLoader = lazy(() => import("./pages/admin/loaders/AccountsLoader.js"));
const CourseGridLoader = lazy(() => import("./pages/admin/loaders/CourseGridLoader.js"));
const HelpLoader = lazy(() => import("./pages/admin/loaders/HelpLoader.js"));
const Compitition_preview = lazy(() => import("./pages/admin/Compitition_preview.js"));
const Reflection_add = lazy(() => import("./pages/admin/Reflection_add.js"));
const Reflection_list = lazy(() => import("./pages/admin/Reflections_list.js"));
const Assigned_to_student = lazy(() => import("./pages/parent/Assigned_to_student.js"));
const Student_portfolio_Activity1 = lazy(() => import("./pages/student/student_portdolio_activity1.js"));
const Student_portfolio_Activity2 = lazy(() => import("./pages/student/student_portdolio_activity2.js"));
const Student_portfolio_Add1 = lazy(() => import("./pages/student/student_portdolio_add1.js"));
const Student_portfolio_Add2 = lazy(() => import("./pages/student/student_portdolio_add2.js"));
const Student_portfolio_Activity3 = lazy(() => import("./pages/student/student_portdolio_activity3.js"));
const Student_portfolio_Activity4 = lazy(() => import("./pages/student/student_portdolio_activity4.js"));
const Student_portfolio_Activity5 = lazy(() => import("./pages/student/student_portdolio_activity5.js"));
const SignInVerification = lazy(() => import("./pages/Signup/SignInVerification.js"));
const ForgotPassword = lazy(() => import("./pages/Signup/forgot_password.js"));
const ResetPassword = lazy(() => import("./pages/Signup/Reset_password.js"));
const SignUp = lazy(() => import("./pages/Signup/SignUp.js"));
const SignInMain = lazy(() => import("./pages/Signup/SignInWithAccounts.js"));
const Billing = lazy(() => import("./pages/parent/Billing.js"));
const CourseAddLoader = lazy(() => import("./pages/admin/loaders/courseAddLoader.js"));
const Student_report = lazy(() => import("./pages/student/student_report.js"));
const Not_found = lazy(() => import("./404.js"));
export const Routes1 = () => {
  const [userRole, setUserRole] = useState("");
  
  useEffect(() => {
    const checkUserRole = () => {
      const role = Cookies.get("Accounttype");
  
      if (role) {
        setUserRole(role);
      } else {
        setTimeout(checkUserRole, 100); 
      } 
    };

    checkUserRole();
  }, []);

  return (
    <Router>
     <Suspense fallback={<div>Loading...</div>}>   
     <Routes>
        {
          userRole !== "" ? (<>
            <Route path='/login' element={<Login />} />
            {(userRole === "Admin" || userRole === "Editor") && (
              <> 
              {/* <Route path="/" element={<Dashboard />} /> */}
              <Route path="/admin_dashboard" element={<Dashboard />} />
                <Route path='/add-admin-lesson' element={<Admin_add_lesson2 />} />
                <Route path='/add-admin-course' element={<Admin_add_course2 />} />
                <Route path='/add-admin-course/:id' element={<Admin_add_course2 />} />
                {/* <Route path='/admin_pathway_grid' element={<Admin_pathway_grid />} />
                <Route path='/admin_pathway_grid/:id' element={<Admin_pathway_grid />} /> */}
                <Route path='/admin_course_demo' element={<RouteAuthGuard><Admin_Course_detail /></RouteAuthGuard>} />
                <Route path='/admin_course_demo/:id' element={<RouteAuthGuard><Admin_Course_detail /></RouteAuthGuard>} />
                <Route path='/help_preview/:id' element={<Help_preview />} />
                {/* <Route path='/add-admin-pathway' element={<Admin_add_pathway />} />
                <Route path='/add-admin-pathway/:id' element={<Admin_add_pathway />} /> */}
                <Route path='/admin_course_grid' element={<Admin_course_grade_view />} />
                <Route path='/admin_course_list' element={<Admin_course_list_view />} />
                <Route path='/account_list' element={<Users_list_table />} />
                <Route path='/admin_parent_form' element={<User_parent_form />} />
                <Route path='/admin_student_form' element={<User_student_form />} />
                <Route path='/company_reapproval' element={<Company_Reapproval />} />
                <Route path='/event_list' element={<Admin_competition_list_table />} />
                <Route path='/event_add' element={<Admin_competition_form />} />
                <Route path='/event_add/:id' element={<Admin_competition_form />} />
                <Route path='/promo_add' element={<Admin_promo />} />
                <Route path='/promo_add_' element={<Admin_promo_ />} />
                <Route path='/admin_report' element={<Admin_user_list />} />
                <Route path='/help_list' element={<Admin_help />} />
                <Route path='/admin_dashboard' element={<Dashboard />} />
                <Route path='/help_add/:id' element={<Admin_help_add />} />
                <Route path='/help_add/' element={<Admin_help_add />} />
                <Route path='/event_preview/:id' element={<Compitition_preview />} />
                <Route path='/reflection_add' element={<Reflection_add />} />
                <Route path='/reflection_add/:id' element={<Reflection_add />} />
                <Route path='/reflection_list' element={<Reflection_list />} />
                <Route path='/course_add_loader' element={<CourseAddLoader />} />
                {/* <Route path='/cms_form' element={<CMS_Email_form />} />
                <Route path='/cms_form/:id' element={<CMS_Email_form_Update />} /> */}
                 
                <Route path='/account_add' element={<Add_profile />} />
                <Route path='/account_add/:id' element={<Add_profile />} />
                <Route path='/account_add/:id/add_student' element={<Add_profile_student />} />
                <Route path='/add_user' element={<Add_user />} />
                <Route path="/create_student" element={<Create_student_account />} /></>
            )}
            {userRole === "Company" && (
              <><Route path='/company_dashboard' element={<Company_dashboard />} />
             {/* <Route path='/' element={<Company_dashboard />} /> */}
                <Route path='/course_catalog' element={<Course_catalog />} />
                <Route path='/company_account' element={<Company_account />} />
                <Route path='/company_member_add' element={<Company_member_add />} />
                <Route path='/company_member_add/:id' element={<Company_member_add />} />
                <Route path='/company_account/:id' element={<Company_account />} />
                <Route path='/company_profile_detail' element={<Company_profile_detail />} />
                <Route path='/company_profile/:id' element={<Company_profile_detail />} />
                <Route path='/company_profile_list_table' element={<Company_profile_list_table />} />
                <Route path='/company_membership' element={<Company_membership />} />
                <Route path='/company_membership_exit' element={<Company_Membership_exit />} />
                <Route path='/company_membership_form' element={<Company_Membership_form />} />
                <Route path='/company_invite' element={<Company_invite />} />
                <Route path='/company_help' element={<Company_help />} />
                <Route path='/company_billing' element={<Company_billing />} />
                <Route path='/company_contact' element={<Company_contact_us />} />
                <Route path='/company_contact_learning_advisor' element={<Company_contact_learning />} />
                <Route path='/company_contact_representative' element={<Company_Contact_representative />} />
                <Route path='/company_leave_feedback' element={<Company_Leave_feedback />} />
                <Route path='/company_thanks_for_contact' element={<Company_Thanks_for_Contact />} />
                <Route path='/company_answer/:id' element={<Company_Help_answer_page />} /></>
            )}

            {userRole === "Parent" && (
              <> <Route path='/parent_dashboard' element={<Parent_dashboard />} />
               {/* <Route path='/' element={<Parent_dashboard />} /> */}
                <Route path='/parent_planner' element={<Parent_planner />} />
                <Route path='/course_enrolled' element={<Course_enrolled />} />
                <Route path='/parent_course_grid' element={<Parent_Course_grid />} />
                <Route path='/parent_course_grid/:id' element={<Parent_Course_grid />} />
                {/* <Route path='/parent_course_list' element={<Parent_course_list />} />
                <Route path='/parent_course_list/:id' element={<Parent_course_list />} /> */}
                <Route path='/parent_help' element={<Parent_help />} />
                <Route path='/contact' element={<Contact_us />} />
                <Route path='/contact_learning_advisor' element={<Contact_learning_advisor />} />
                <Route path='/contact_representative' element={<Contact_representative />} />
                <Route path='/leave_feedback' element={<Leave_feedback />} />
                <Route path='/thanks_for_contact' element={<Thanks_for_Contact />} />
                <Route path='/answer/:id' element={<Help_answer_page />} />
                <Route path='/parent_account' element={<Parent_account />} />
                <Route path='/parent_structured_learning_2' element={<Structured_learning_2 />} />
                <Route path='/parent_report' element={<Parent_report />} />
                <Route path='/parent_membership_exit' element={<Parent_membership_exit />} />
                <Route path='/parent_self_learning' element={<Self_learning />} />
                <Route path='/parent_self_learning_2' element={<Self_learning_2 />} />
                <Route path='/course_list_loader' element={<Course_list_loader />} />
                <Route path='/parent_membership' element={<Parent_membership />} />
                <Route path='/parent_membership_invite' element={<Parent_membership_invite />} />
                <Route path='/parent_membership_form' element={<Parent_membership_form />} />
                <Route path='/parent_profile_ready' element={<Parent_profile_ready />} />
                <Route path='/assigned_to_student' element={<Assigned_to_student />} />
                <Route path='/parent_profile' element={<Parent_profile />} />
                <Route path='/parent_billing' element={<Billing />} />
                <Route path='/parent_student_profile' element={<Parent_student_profile />} />
                <Route path='/student_mode' element={<Student_mode />} />
                <Route path='/parent_portfolio' element={<Parent_portfolio />} />
                <Route path='/student_enrolled_courses' element={<Student_enrolled_courses />} />
                <Route path='/student_achievement' element={<Student_achievement />} />
                <Route path='/student_portfolio' element={<Student_portfolio />} />
                <Route path='/student_certificate' element={<Student_certificate />} />
                <Route path='/student_competition' element={<Student_competition />} />
                <Route path='/student_report' element={<Student_report/>} />
                <Route path='/student_course_interest' element={<Student_course_interest />} />
                <Route path='/student_course_detail' element={<Student_Course_detail />} /></>
            )}

            {userRole === "Student" && (
              <> <Route path='/student_enrolled_courses' element={<Student_enrolled_courses />} />
             {/* <Route path='/' element={<Student_enrolled_courses />} /> */}
                <Route path='/student_achievement' element={<Student_achievement />} />
                <Route path='/student_portfolio' element={<Student_portfolio />} />
                <Route path='/student_certificate' element={<Student_certificate />} />
                <Route path='/student_course_detail/:id' element={<Student_Course_detail />} />
                <Route path='/student_competition' element={<Student_competition />} />
                <Route path='/student_course_interest' element={<Student_course_interest />} />
                <Route path='/student_course_detail' element={<Student_Course_detail />} />
                <Route path='/student_report' element={<Student_report/>} />
                {/* New SP */}
                <Route path='/student_portfolio_Activity1' element={<Student_portfolio_Activity1 />} />
                <Route path='/student_portfolio_Activity2' element={<Student_portfolio_Activity2 />} />
                <Route path='/student_portfolio_Add1' element={<Student_portfolio_Add1 />} />
                <Route path='/student_portfolio_Add2' element={<Student_portfolio_Add2 />} />
                <Route path='/student_portfolio_Activity3' element={<Student_portfolio_Activity3 />} />
                <Route path='/student_portfolio_Activity4' element={<Student_portfolio_Activity4 />} />
                <Route path='/student_portfolio_Activity5' element={<Student_portfolio_Activity5 />} />
              </>
            )}

            {userRole === "Company" && (
              <Route
                path='*'
                element={<Not_found />}
              />
            )}
            {(userRole === "Admin" || userRole === "Editor") && (
              <Route
                path='*'
                element={<Not_found />}
              />
            )}
            {userRole === "Parent" && (
              <Route
                path='*'
                element={<Not_found />}
              />
            )}
            {userRole === "Student" && (
              <Route
                path='*'
                element={<Not_found />}
              />
            )}
            <Route path="/user_access" element={<User_access />} />
          </>) : (<>
            <Route path='/login' element={<Login />} />
            <Route path='/add-admin-lesson' element={<RouteAuthGuard><Admin_add_lesson2 /></RouteAuthGuard>} />
            <Route path='/add-admin-course' element={<RouteAuthGuard><Admin_add_course2 /></RouteAuthGuard>} />
            <Route path='/add-admin-course/:id' element={<RouteAuthGuard><Admin_add_course2 /></RouteAuthGuard>} />
            {/* <Route path='/admin_pathway_grid' element={<RouteAuthGuard><Admin_pathway_grid /></RouteAuthGuard>} />
            <Route path='/admin_pathway_grid/:id' element={<RouteAuthGuard><Admin_pathway_grid /></RouteAuthGuard>} /> */}
            <Route path='/help_preview/:id' element={<RouteAuthGuard><Help_preview /></RouteAuthGuard>} />
            <Route path='/event_preview/:id' element={<RouteAuthGuard><Compitition_preview /></RouteAuthGuard>} />
            <Route path='/reflection_add' element={<RouteAuthGuard><Reflection_add /></RouteAuthGuard>} />
            <Route path='/reflection_add/:id' element={<RouteAuthGuard><Reflection_add /></RouteAuthGuard>} />
            <Route path='/reflection_list' element={<RouteAuthGuard><Reflection_list /></RouteAuthGuard>} />
            {/* <Route path='/add-admin-pathway' element={<RouteAuthGuard><Admin_add_pathway /></RouteAuthGuard>} />
            <Route path='/add-admin-pathway/:id' element={<RouteAuthGuard><Admin_add_pathway /></RouteAuthGuard>} /> */}
            <Route path='/admin_course_grid' element={<RouteAuthGuard><Admin_course_grade_view /></RouteAuthGuard>} />
            <Route path='/admin_course_list' element={<RouteAuthGuard><Admin_course_list_view /></RouteAuthGuard>} />
            <Route path='/admin_course_demo' element={<RouteAuthGuard><Admin_Course_detail /></RouteAuthGuard>} />
            <Route path='/admin_course_demo/:id' element={<RouteAuthGuard><Admin_Course_detail /></RouteAuthGuard>} />
            <Route path='/account_list' element={<RouteAuthGuard><Users_list_table /></RouteAuthGuard>} />
            <Route path='/admin_parent_form' element={<RouteAuthGuard><User_parent_form /></RouteAuthGuard>} />
            <Route path='/admin_student_form' element={<RouteAuthGuard><User_student_form /></RouteAuthGuard>} />
            <Route path='/company_reapproval' element={<RouteAuthGuard><Company_Reapproval /></RouteAuthGuard>} />
            <Route path='/event_list' element={<RouteAuthGuard><Admin_competition_list_table /></RouteAuthGuard>} />
            <Route path='/event_add' element={<RouteAuthGuard><Admin_competition_form /></RouteAuthGuard>} />
            <Route path='/event_add/:id' element={<RouteAuthGuard><Admin_competition_form /></RouteAuthGuard>} />
            <Route path='/promo_add' element={<RouteAuthGuard><Admin_promo /></RouteAuthGuard>} />
            <Route path='/promo_add_' element={<RouteAuthGuard><Admin_promo_ /></RouteAuthGuard>} />
            <Route path='/admin_report' element={<RouteAuthGuard><Admin_user_list /></RouteAuthGuard>} />
            <Route path='/help_list' element={<RouteAuthGuard><Admin_help /></RouteAuthGuard>} />
            <Route path='/admin_dashboard' element={<RouteAuthGuard><Dashboard /></RouteAuthGuard>} />
            <Route path='/help_add/:id' element={<RouteAuthGuard><Admin_help_add /></RouteAuthGuard>} />
            <Route path='/help_add/' element={<RouteAuthGuard><Admin_help_add /></RouteAuthGuard>} />
            <Route path='/account_add' element={<RouteAuthGuard><Add_profile /></RouteAuthGuard>} />
            <Route path='/account_add/:id' element={<RouteAuthGuard><Add_profile /></RouteAuthGuard>} />
            <Route path='/account_add/:id/add_student' element={<RouteAuthGuard><Add_profile_student /></RouteAuthGuard>} />
            <Route path='/add_user' element={<RouteAuthGuard><Add_user /></RouteAuthGuard>} />
            <Route path="/create_student" element={<RouteAuthGuard><Create_student_account /></RouteAuthGuard>} />
            <Route path='/company_dashboard' element={<RouteAuthGuard><Company_dashboard /></RouteAuthGuard>} />
            <Route path='/course_catalog' element={<RouteAuthGuard><Course_catalog /></RouteAuthGuard>} />
            <Route path='/company_account' element={<RouteAuthGuard><Company_account /></RouteAuthGuard>} />
            <Route path='/company_member_add' element={<RouteAuthGuard><Company_member_add /></RouteAuthGuard>} />
            <Route path='/company_member_add/:id' element={<RouteAuthGuard><Company_member_add /></RouteAuthGuard>} />
            <Route path='/company_account/:id' element={<RouteAuthGuard><Company_account /></RouteAuthGuard>} />
            <Route path='/company_profile_detail' element={<RouteAuthGuard><Company_profile_detail /></RouteAuthGuard>} />
            <Route path='/company_profile/:id' element={<RouteAuthGuard><Company_profile_detail /></RouteAuthGuard>} />
            <Route path='/company_profile_list_table' element={<RouteAuthGuard><Company_profile_list_table /></RouteAuthGuard>} />
            <Route path='/company_membership' element={<RouteAuthGuard><Company_membership /></RouteAuthGuard>} />
            <Route path='/company_membership_exit' element={<RouteAuthGuard><Company_Membership_exit /></RouteAuthGuard>} />
            <Route path='/company_membership_form' element={<RouteAuthGuard><Company_Membership_form /></RouteAuthGuard>} />
            <Route path='/company_invite' element={<RouteAuthGuard><Company_invite /></RouteAuthGuard>} />
            <Route path='/company_help' element={<RouteAuthGuard><Company_help /></RouteAuthGuard>} />
            <Route path='/company_contact' element={<RouteAuthGuard><Company_contact_us /></RouteAuthGuard>} />
            <Route path='/company_contact_learning_advisor' element={<RouteAuthGuard><Company_contact_learning /></RouteAuthGuard>} />
            <Route path='/company_contact_representative' element={<RouteAuthGuard><Company_Contact_representative /></RouteAuthGuard>} />
            <Route path='/company_leave_feedback' element={<RouteAuthGuard><Company_Leave_feedback /></RouteAuthGuard>} />
            <Route path='/company_thanks_for_contact' element={<RouteAuthGuard><Company_Thanks_for_Contact /></RouteAuthGuard>} />
            <Route path='/company_answer/:id' element={<RouteAuthGuard><Company_Help_answer_page /></RouteAuthGuard>} />
            <Route path='/parent_dashboard' element={<RouteAuthGuard><Parent_dashboard /></RouteAuthGuard>} />
            <Route path='/parent_planner' element={<RouteAuthGuard><Parent_planner /></RouteAuthGuard>} />
            <Route path='/course_enrolled' element={<RouteAuthGuard><Course_enrolled /></RouteAuthGuard>} />
            <Route path='/parent_course_grid' element={<RouteAuthGuard><Parent_Course_grid /></RouteAuthGuard>} />
            <Route path='/parent_course_grid/:id' element={<RouteAuthGuard><Parent_Course_grid /></RouteAuthGuard>} />
            {/* <Route path='/parent_course_list' element={<RouteAuthGuard><Parent_course_list /></RouteAuthGuard>} />
            <Route path='/parent_course_list/:id' element={<RouteAuthGuard><Parent_course_list /></RouteAuthGuard>} /> */}
            <Route path='/parent_help' element={<RouteAuthGuard><Parent_help /></RouteAuthGuard>} />
            <Route path='/contact' element={<RouteAuthGuard><Contact_us /></RouteAuthGuard>} />
            <Route path='/contact_learning_advisor' element={<RouteAuthGuard><Contact_learning_advisor /></RouteAuthGuard>} />
            <Route path='/contact_representative' element={<RouteAuthGuard><Contact_representative /></RouteAuthGuard>} />
            <Route path='/leave_feedback' element={<RouteAuthGuard><Leave_feedback /></RouteAuthGuard>} />
            <Route path='/thanks_for_contact' element={<RouteAuthGuard><Thanks_for_Contact /></RouteAuthGuard>} />
            <Route path='/answer/:id' element={<RouteAuthGuard><Help_answer_page /></RouteAuthGuard>} />
            <Route path='/parent_account' element={<RouteAuthGuard><Parent_account /></RouteAuthGuard>} />
            {/* <Route path='/cms_form' element={<RouteAuthGuard><CMS_Email_form /></RouteAuthGuard>} />
            <Route path='/cms_form/:id' element={<RouteAuthGuard><CMS_Email_form_Update /></RouteAuthGuard>} /> */}
            <Route path='/parent_structured_learning_2' element={<RouteAuthGuard><Structured_learning_2 /></RouteAuthGuard>} />
            <Route path='/parent_report' element={<RouteAuthGuard><Parent_report /></RouteAuthGuard>} />
            <Route path='/parent_membership_exit' element={<RouteAuthGuard><Parent_membership_exit /></RouteAuthGuard>} />
            <Route path='/parent_self_learning' element={<RouteAuthGuard><Self_learning /></RouteAuthGuard>} />
            <Route path='/student_mode' element={<RouteAuthGuard><Student_mode /></RouteAuthGuard>} />
            <Route path='/parent_self_learning_2' element={<RouteAuthGuard><Self_learning_2 /></RouteAuthGuard>} />
            <Route path='/course_list_loader' element={<RouteAuthGuard><Course_list_loader /></RouteAuthGuard>} />
            <Route path='/parent_membership' element={<RouteAuthGuard><Parent_membership /></RouteAuthGuard>} />
            <Route path='/parent_membership_invite' element={<RouteAuthGuard><Parent_membership_invite /></RouteAuthGuard>} />
            <Route path='/parent_membership_form' element={<RouteAuthGuard><Parent_membership_form /></RouteAuthGuard>} />
            <Route path='/parent_profile_ready' element={<RouteAuthGuard><Parent_profile_ready /></RouteAuthGuard>} />
            <Route path='/assigned_to_student' element={<RouteAuthGuard><Assigned_to_student /></RouteAuthGuard>} />
            <Route path='/parent_profile' element={<RouteAuthGuard><Parent_profile /></RouteAuthGuard>} />
            <Route path='/parent_billing' element={<RouteAuthGuard><Billing /></RouteAuthGuard>} />
            <Route path='/company_billing' element={<RouteAuthGuard><Company_billing /></RouteAuthGuard>} />
            <Route path='/parent_student_profile' element={<RouteAuthGuard><Parent_student_profile /></RouteAuthGuard>} />
            <Route path='/parent_portfolio' element={<RouteAuthGuard><Parent_portfolio /></RouteAuthGuard>} />
            <Route path='/student_enrolled_courses' element={<RouteAuthGuard><Student_enrolled_courses /></RouteAuthGuard>} />
            <Route path='/student_achievement' element={<RouteAuthGuard><Student_achievement /></RouteAuthGuard>} />
            <Route path='/student_portfolio' element={<RouteAuthGuard><Student_portfolio /></RouteAuthGuard>} />
            <Route path='/student_certificate' element={<RouteAuthGuard><Student_certificate /></RouteAuthGuard>} />
            <Route path='/student_report' element={<RouteAuthGuard><Student_report /></RouteAuthGuard>} />
            <Route path='/student_competition' element={<RouteAuthGuard><Student_competition /></RouteAuthGuard>} />
            <Route path='/student_course_interest' element={<RouteAuthGuard><Student_course_interest /></RouteAuthGuard>} />
            <Route path='/student_course_detail' element={<RouteAuthGuard><Student_Course_detail /></RouteAuthGuard>} />
            <Route path='/student_course_detail/:id' element={<RouteAuthGuard><Student_Course_detail /></RouteAuthGuard>} />
          </>
          )
        }
        <Route path='/student_enrolled_loader' element={<RouteAuthGuard><EnrolledCourseLoader /></RouteAuthGuard>} />
        <Route path='/student_certificate_loader' element={<RouteAuthGuard><CertificateLoader /></RouteAuthGuard>} />
        <Route path='/student_course_detail_loader' element={<RouteAuthGuard><CourseDetailLoader /></RouteAuthGuard>} />
        <Route path='/student_portfolio_loader' element={<RouteAuthGuard><PortfolioLoader /></RouteAuthGuard>} />
        <Route path='/student_course_list_loader' element={<RouteAuthGuard><Course_list_loader /></RouteAuthGuard>} />
        <Route path='/student_achievement_loader' element={<RouteAuthGuard><AchievementLoader /></RouteAuthGuard>} />
        <Route path='/student_event_loader' element={<RouteAuthGuard><EventLoader /></RouteAuthGuard>} />
        <Route path='/course_add_loader' element={<RouteAuthGuard><CourseAddLoader /></RouteAuthGuard>} />
        <Route path='/list_loader' element={<RouteAuthGuard><ListLoader /></RouteAuthGuard>} />
        <Route path='/account_loader' element={<RouteAuthGuard><AccountsLoader /></RouteAuthGuard>} />
        <Route path='/course_grid_loader' element={<RouteAuthGuard><CourseGridLoader /></RouteAuthGuard>} />
        <Route path='/help_loader' element={<RouteAuthGuard><HelpLoader /></RouteAuthGuard>} />
        {/* <Route path='/user_login' element={<User_login />} /> */}
        
        <Route path='/sign-inverification' element={<SignInVerification />} />
        <Route path='/user_login' element={<SignInMain />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/user-access' element={<User_access />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='*' element={<RouteAuthGuard><Not_found /></RouteAuthGuard>} />

      </Routes>
      </Suspense>
   
    </Router>
  );
};

// const RouteAuthGuard = ({ children }) => {
//   const token = Cookies.get("token");
//   const Accounttype = Cookies.get("Accounttype");
//   const sp = localStorage.getItem("staffPermission")
//   const staffPermissionInt = parseInt(sp, 10);
//   const location = useLocation();
//   const path = location.pathname;
 
//   let exactPath;

//   if (path === "/" && Accounttype === "Admin") {
//       exactPath = "/admin_dashboard";
//       return <Navigate to={exactPath} replace />;
//   } else if (path === "/" && Accounttype === "Parent") {
//       exactPath = "/parent_dashboard";
//       return <Navigate to={exactPath} replace />;
//   } else if (path === "/" && Accounttype === "Company") {
//       exactPath = "/company_dashboard";
//       return <Navigate to={exactPath} replace />;
//   } else if (path === "/" && Accounttype === "Student") {
//       exactPath = "/student_enrolled_courses";
//       return <Navigate to={exactPath} replace />;
//   } else if (path === "/" && Accounttype === "Staff" && staffPermissionInt === 0) {
//       exactPath = "/admin_dashboard";
//       return <Navigate to={exactPath} replace />;
//   } else {
//       exactPath = location.pathname;
//   }
  
//   localStorage.setItem("lastRoute", exactPath);
   
//   if (!token) {
//     return <Navigate to={"/login"} replace />;
//   }
//   return children;
// };
const RouteAuthGuard = ({ children }) => {
  const token = Cookies.get("token");
  const accountType = Cookies.get("Accounttype");

  // Check if token exists
  if (!token) {
    // Redirect based on Accounttype when token doesn't exist
    if (accountType === "Company" || accountType === "Parent" || accountType === "Student") {
      return <Navigate to={"/user_login"} replace />;
    } else if (accountType === "Admin" || accountType === "Editor") {
      return <Navigate to={"/login"} replace />;
    } else {
      // Default redirect when both token and Accounttype don't exist
      return <Navigate to={"/login"} replace />;
    }
  }

  // If token exists, allow rendering children
  return children;
};

export default RouteAuthGuard;;


