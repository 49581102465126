import "../pages/Signup/style.css"
import { Link, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import Cookies from "js-cookie";
import Button from "../components/button/Button";
import { SignInPasswordAdmin } from "./Signup/SignInPasswordAdmin";
import { Halmet } from "../components/Helmet/Helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryption } from "../service/utils";
import { Login_api } from "../service/api";
export const Login = () => {
  const [email, setemail] = useState("");
  const [erremail, seterremail] = useState("");
  const [loginStep, setLoginstep] = useState(1)
  const navigate = useNavigate()

  useEffect(() => {
    const isAuthenticated = Cookies.get("token");
    const Accounttype = Cookies.get("Accounttype");
    const staffper = localStorage.getItem("staffPermission");
    if (isAuthenticated) {
      if (Accounttype === "Admin" || Accounttype === "Editor") {
        window.location.href = "/admin_dashboard"

      } else if (Accounttype === "Company") {
        window.location.href = "/company_dashboard"


      }
      else if (Accounttype === "Parent") {
        window.location.href = "/parent_dashboard"

      }
      else if (Accounttype === "Admin" && staffper === 0) {
        window.location.href = "/admin_dashboard"

      }
      else if (Accounttype === "Student") {
        window.location.href = "/student_enrolled_courses"
      }
    }
  }, []);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const subminform = async () => {
    let hasError = false;
    const formattedEmail = email.toLowerCase();

    if (formattedEmail === "" || formattedEmail == null) {
      seterremail("Email is required");
      hasError = true;
    } else if (!validateEmail(formattedEmail)) {
      seterremail("Please enter a valid email");
      hasError = true;
    } else {
      seterremail("");
    }
    if (hasError) {
      return;
    }
    try {
      const res = await Login_api(email , null , 1)
      if(res.status === 200){
        setLoginstep(2)

      }else {
        toast.error(res.response.data.message ? res.response.data.message : "Something went wrong!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });
      }
    }catch(error) {
      console.log(error)
    }


  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      subminform();
    }

  };
  return (
    <div className="main_cont">
        <Halmet title="Login" />
        <ToastContainer />
    <header className="admin_layout_head">
      

          <div className="admin_layout_logo">
            <img src="/img/platform_logo.svg" />
          </div>
          <div className="admin_layout_head2">
            <div className="admin_layout_pagename"></div>
           
          </div>
        </header>
      {
        loginStep === 1 && (
          <div className="bg-container">  <div className="login_main_wrapper p-4">
            <div className="login_main card_glow p-5 padding-small text-start">
              <div className="d-flex flex-column gap-3">
                <h1 className="sub_heading_black f-24">
                  Sign in
                </h1>
                <div>
                  <label for="password" className="form-label mt-0">
                    Email
                    {/* <span className="user_required_feild">*</span> */}
                  </label>
                  <input type="email" autoFocus={true} className="form-control" value={email}
                    onChange={(e) => setemail(e.target.value)} onKeyPress={handleKeyPress}
                  />
                  {
                    erremail && (
                      <div className="error_txt d-flex align-items-center">
                        <img src="/img/warnIcon.svg" alt="" className="me-1" height={18} width={18} />
                        {erremail}
                      </div>
                    )
                  }
                </div>
                <div className="d-flex justify-content-center align-content-center mt-3">
                  <Button className={"semi_violet_button"} onClick={subminform}>Next</Button>
                </div>
                
                <div className="text-center mt-3 light-small-text">By continuing, you agree to Edbition’s terms and conditions⁠. Read our <span className="txt-violet text-decoration-underline">Privacy policy.</span></div>


              </div>
            </div>
          </div></div>
        )
      }
      {
        loginStep === 2 && (
          <SignInPasswordAdmin email={email} />
        )
      }
    </div>



  )
}