import React from 'react'

const Button = ({ children, className, type, style, image, img2, onClick, download, certificateTitle, courseTitle, userFullName }) => {
  const handleClick = () => {
    if (download) {
      const link = document.createElement('a');
      link.href = download;
      const userName = userFullName.replace(/\s+/g, '_');
      const coursetitle = courseTitle.replace(/\s+/g, '_');
      const certificatetitle = certificateTitle?.replace(/\s+/g, '_');
      link.download = `${userName}_${coursetitle}_${certificatetitle}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    if (onClick) {
      onClick();
    }
  };
  return (
    <button
      aria-label={children}
      onClick={handleClick}
      type={type}
      style={style}
      className={`d-flex align-items-center gap-2 ${className}`} >
      {image && <img src={image} alt={children} className="img_svg" />}

      <span>{children}</span>
      {img2 && <img src={img2} alt={children} className='img_svg' />}
    </button>
  )
}

export default Button
