import React, { useState } from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ items }) => {
  const [activeImg, setActiveImg] = useState(null);

  return (
    <ul className="breadcrumb">
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <li className="breadcrumb-separator">
              <img src="/img/breadcrumbicon.svg" alt="" />{" "}
            </li>
          )}

          {item.url ? (
            <li
              onMouseEnter={() => setActiveImg(item.activeImg)}
              onMouseLeave={() => setActiveImg(null)}
              className={`breadcrumb-link ${item.active ? 'active' : ''}`}
            >
              {index === 0 && item.img && (
                <img
                  src={item.active || activeImg ? item.activeImg : item.img}
                  alt="icon"
                  className="me-2"
                />
              )}
              <Link className={`breadcrumb-link ${item.active || activeImg ? 'active' : ''}`} to={item.url}>{item.label}</Link>
            </li>
          ) : (
            <li
              onMouseEnter={() => setActiveImg(item.activeImg)}
              onMouseLeave={() => setActiveImg(null)}
              className={`breadcrumb-link  ${item.active ? 'active' : ''}`}
              onClick={item.onClick}
            >
              {index === 0 && item.img && (
                <img
                  src={item.active || activeImg ? item.activeImg : item.img}
                  alt="icon"
                  className="me-2"
                />
              )}
              {item.label}
            </li>
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default Breadcrumb;