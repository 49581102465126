import React, { useEffect, useState } from "react";
import Button from "../button/Button";
import Modal from "./modal";
import {useNavigate} from "react-router-dom"

const DeletedModal = ({
  deletedModal,
  setDeletedModal, redirectRoute, section
}) => {
  const navigate = useNavigate()

    const [timerId, setTimerId] = useState(null);

    useEffect(() => {
      console.log("deletedModal", deletedModal);
      if (deletedModal) {
        document.documentElement.style.overflowY = "hidden";
  
        const id = setTimeout(() => {
          console.log("Redirecting to:", redirectRoute);
          if (redirectRoute) {
            window.location.href = redirectRoute;
          }
          setDeletedModal(false);
        }, 12000);
  
        // Store the timer ID
        return () => clearTimeout(id);
      } else {
        document.documentElement.style.overflowY = "auto";
      }
    }, [deletedModal, setDeletedModal, redirectRoute]);
  

  return (
    <>
      {deletedModal && (
        <Modal>
          <div className="modal_user_confirmation p-3">
            <img
              src="/img/deletedGIF.gif"
              
              
              alt="delete"
            />

            <div className="font-16">
              All done, {section ? section : "account"} is deleted
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DeletedModal;
