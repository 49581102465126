import OTPInput from "react-otp-input"
import "./style.css"
import Button from "../../components/button/Button"
import LinkTo from "../../components/link/Link"
import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Login_api } from "../../service/api"
export const SignInPasswordAdmin = ({ email }) => {
  const navigate = useNavigate()
  const [password, setpassword] = useState("");
  const [errpassword, seterrpassword] = useState("");
  const [isPasswordShow, setIsPasswordShow] = useState(false)
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      subminform();
    }
  };
  const subminform = async () => {
    let hasError = false;
    if (password === "" || password == null) {
      seterrpassword("Password is required");
      hasError = true;
    } else {
      seterrpassword("");
    }

    if (hasError) {
      return;
    }
    const res = await Login_api(email, password, null);
    console.log("res", res)
    // console.log(window.location.shref)
    if (res.status === 200) {
      const route = localStorage.getItem("lastRoute")

      const role = Cookies.get("Accounttype")
      console.log("route", route)

      console.log("role", role)
      if (route) {
        console.log("route inside", route)
        navigate(route)
      } else {
        console.log("jhdfghkfgkjgfnjnjnh")
        if (res.data.Data.AccountType === "4" || res.data.Data.AccountType === "5") {
          window.location.href = "/admin_dashboard"

        } else if (res.data.Data.AccountType === "2") {
          window.location.href = "/company_dashboard"

        }
        else if (res.data.Data.AccountType === "4" && res.data.Data.StaffPermission === 0) {
          localStorage.setItem("staffPermission", 0)
          window.location.href = "/admin_dashboard"

        }
        else if (res.data.Data.AccountType === "3") {

          window.location.href = "/parent_dashboard"
        } else if (res.data.Data.AccountType === "1") {
          window.location.href = "/student_enrolled_courses"
        }
      }

      // if(res.data.Data.AccountType === "Admin"){
      //   window.location.href ="/admin_dashboard"

      // }else if(res.data.Data.AccountType === "Company"){
      //   window.location.href ="/company_dashboard"

      // }
      // else if(res.data.Data.AccountType === "Staff" && res.data.Data.StaffPermission === 0){
      //   localStorage.setItem("staffPermission", 0)
      //   window.location.href ="/admin_dashboard"

      // }
      // else if(res.data.Data.AccountType === "Parent"){
      // await planner_get_api()
      //   window.location.href ="/parent_dashboard"
      // }else  if(res.data.Data.AccountType === "Student"){
      //   window.location.href ="/student_enrolled_courses"
      // }

      console.log("login response", res);
      console.log(res.data.Data.Accounttype);
      toast.success("Login successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      Cookies.set("token", res.data.userToken)
      const Account = res.data.Data.AccountType
      const Accounttype = Account === "1" ? "Student" : Account === "2" ? "Company" : Account === "3" ? "Parent" : Account === "4" ? "Admin" : Account === "5" ? "Editor" : null;

      Cookies.set("Accounttype", Accounttype)
      // localStorage.setItem("token", res.data.userToken);
      // localStorage.setItem("Accounttype", res.data.Data.AccountType);
      // Cookies.set("StaffId", res.data.Data.StaffID);
      localStorage.setItem("FirstName", res.data.Data.FirstName)
      localStorage.setItem("LastName", res.data.Data.LastName)
    } else {
      toast.error(`${res?.response?.data?.message ? res?.response?.data?.message : 'Something went wrong!'}`, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    console.log("email and password", email, password)

  };

  return (
    <div className="bg-container">   
      <ToastContainer />
      <div className="login_main_wrapper p-4">
      <div className="login_main card_glow p-5 padding-small text-start">
        <div className="d-flex flex-column gap-3">
          <h1 className="sub_heading_black f-24">
            Sign in
          </h1>
          <div>
            <label for="password" className="form-label">
              Password
         
            </label>
            <div className="password_eye_block">
              <input
                type={`${isPasswordShow ? "text" : "password"}`}
                id="password"
                autoFocus={true}
                name="password"
                className="form-control passwordField p-r-3v"
                value={password}
                onKeyPress={handleKeyPress}
                onChange={(e) => setpassword(e.target.value)}
                placeholder="Password"
              />
              <img className="eye_icon" src={`${isPasswordShow
                ? "/img/eye_icon.svg"
                : "/img/eye_close.svg"
                }`} alt="eye" onClick={() => setIsPasswordShow(!isPasswordShow)} />
            </div>

            {errpassword && <div className="error_txt d-flex align-items-center">
              <img src="/img/warnIcon.svg" alt="" className="me-1" height={18} width={18} />
              {errpassword}
            </div>}
          </div>
          <div className="d-flex justify-content-center align-content-center mt-3">
            <Button className={"semi_violet_button"} onClick={subminform}>Sign in</Button>
          </div>
          <div className="text-center mt-3 -mb-20 lnk"><Link className="link" to={"/forgot-password"}>Forgot password?</Link></div>

        </div>
      </div>
    </div></div>

  )
}