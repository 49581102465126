import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Student_token } from "../service/client_api";

export const Adminsidebar = ({
  item,
  menuOpen,
  setMenuOpen,
  isShow,
  setIsShow,
  isMobileMenuOpen,
  toggleStudentMenu,
  isStudentMenuOpen,
  toggleMobileMenu,
  activeMenu, setActiveMenu
}) => {

  const location = useLocation();
  console.log("location", location)
  const routeName = location.pathname;
  const [isNone, setIsNone] = useState(false)
  const [isOn, setIsOn] = useState(false)
  const [isStudentMode, setIsStudentMode] = useState(false);
  const[ZeroStudents,setZeroStudents]=useState(false)
  const [parentToken, setParentToken] = useState(null);

  useEffect(() => {
    setIsOn(isStudentMenuOpen);
  }, [isStudentMenuOpen]);



  useEffect(() => {
    setIsStudentMode(item[1].children && item[1].children.length === 1);
    setZeroStudents(item[1].children && item[1].children.length === 0);
  }, [item]);

  const handleStudentModeClick = async () => {
   
    if (isStudentMode) {
      const student = item[1].children[0];
      await handleStudentClick(student);

    } else if(ZeroStudents){
      window.location.href ="/student_mode"
    }else{
      
      toggleStudentMenu();
    }
  };

  console.log("menuopen",menuOpen)
  


  useEffect(() => {
    const storedParentToken = Cookies.get("ParentToken");
    if (storedParentToken) {
      setParentToken(storedParentToken);
    }
    if (location.pathname.startsWith("/student_course_detail/") && location.search === "?type=Course") {
      setIsNone(true);
    }


  }, []);

  const navigate = useNavigate();
  const { id } = useParams();
  const handleSignOut = () => {
    const Accounttype = Cookies.get("Accounttype");

    if (Cookies.get("ParentToken")) {
      Cookies.remove("ParentToken");
      Cookies.set("token", parentToken);
      Cookies.set("Accounttype", "Parent");

      setTimeout(() => {
        window.location.href = "/parent_dashboard";
      }, 100);

    } else {
      if (Accounttype === "Admin" || Accounttype === "Editor") {
        navigate("/login");
      } else {
        navigate("/user_login");
      }

      // Remove tokens and other stored data
      const itemsToRemove = [
        "token", "Accounttype", "staffPermission", "FirstName", "ParentInfo",
        "LastName", "selectedcourse", "messeging_token", "selectedCoursesName",
        "id2", "selectedStudentName", "selectedStudent", "memberOptions",
        "MyEditCourse", "MyEditModule", "SelectedLesson", "Data", "courseData",
        "students", "selectedButtons", "selectedStudentName", "selectedStudentId",
        "plannerCount", "selectedCourses", "CourseId", "ModulesID"
      ];

      itemsToRemove.forEach(item => localStorage.removeItem(item));

      Cookies.remove("token");
      Cookies.remove("Attendence");
      Cookies.remove("Accounttype");
      Cookies.remove("StaffId");
    }
  };

  const handleClick = () => {
    toggleStudentMenu();
    setIsOn(!isOn);

  };

  const handleStudentClick = async (student) => {
    console.log("student", student)

    Cookies.set("Accounttype", student.AccountType)
    // Cookies.set("StaffId", student.UserID);
    const parentToken = Cookies.get("token");
    Cookies.set("ParentToken", parentToken);

    const res = await Student_token(student.UserID)
    console.log("res", res)
    Cookies.set("token", res.data.userToken)
    localStorage.setItem("FirstName", res.data.data[0].FirstName);
    localStorage.setItem("LastName", res.data.data[0].LastName);
    window.location.href = '/student_enrolled_courses'

  };
  // console.log(item);
  return (
    <div
      className={`sidebar_container    pt-0 ${isMobileMenuOpen ? "sidebar-open" : ""}`} style={{
        width: menuOpen && "0", // Set the width based on isMenuOpen
        transition: 'width',
        transitionDuration:"0.10s",
        transitionTimingFunction:"ease-in",
        position: menuOpen ? "fixed" : !isMobileMenuOpen ? "relative" :"",
        zIndex: menuOpen && 1,
        left: menuOpen && 0,
        height: menuOpen && "100%",
        overflow: menuOpen && "hidden",
      }}
    >
      {location.pathname.startsWith("/student_course_detail/") &&
        location.search === "?type=Course" && !menuOpen && isShow && (
          <img src="/img/menuCls.svg" alt="" className="cursor_pointer position-absolute" style={{ top: "10px", right: "5px" }} onClick={() => setMenuOpen(!menuOpen)} />
        )
      }
      <ul className="sidebar_menu">
        {item &&
          item.map((item, i) =>
            item.hr ? (
              <hr />
            ) : item.children && item.children.length > 1 ? (
              <>
                <li className="sidebar_menu_list" onClick={handleClick}>
                  <div
                    style={{
                      width: "26px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img src={item.icon} className="w-100" alt={item.name} />
                  </div>
                  <span className="d-flex justify-content-between align-items-center w-100">
                    {item.name}
                    <img
                      src="/img/chevronDownViolet.svg"
                      className={`chevron-icon ${isStudentMenuOpen && "transform-ro-img"
                        }`}
                    />
                  </span>
                </li>
                {isStudentMenuOpen && (
                  <ul className="student-menu">
                    {item.children.map((childItem, index) => (
                      <NavLink
                        key={index}
                        to={childItem.to}
                        activeClassName="active"
                        onClick={() => handleStudentClick(childItem)}
                      >
                        <li>
                          {" "}
                          <div
                            style={{
                              width: "26px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src="/img/student_icon.svg"
                              className="w-100"
                              alt={item.name}
                            />
                          </div>
                          {childItem.FirstName} {childItem.LastName}
                        </li>
                      </NavLink>
                    ))}
                  </ul>
                )}
              </>
            ) : (
              <NavLink key={i}
                to={item.to}
                onClick={
                  item.onClick || (item.name === "Student mode" ? handleStudentModeClick : null) ||
                  (item.name === "Sign out" ? handleSignOut : null)
                }
                className={
                  location.pathname === item.to ||
                    (item.and && item.and.includes(location.pathname))
                    ? "active"
                    : ""
                }
              >
                <li className="sidebar_menu_list">
                  <div
                    style={{
                      width: "26px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img src={item.icon} className="w-100" alt={item.name} />
                  </div>
                  <span className="d-flex justify-content-between align-items-center w-100 pe-2">
                    {item.name}
                    {item.tag !== null && item.tag !== undefined && item.tag !== "" && item.tag !== 0 && item.tag !== "0" && (
                      <span className="sidebar_tag">{item.tag}</span>
                    )}
                  </span>
                </li>
              </NavLink>
            )
          )}
      </ul>
    </div>
  );
};
