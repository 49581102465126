import { AdminLayout } from "../../components/company/AdminLayout";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../components/button/Button.js";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { Halmet } from "../../components/Helmet/Helmet.js";
import { decryption, encryption } from "./../../service/utils.js";

import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "../../components/modal/Alert.js";
import DeletedModal from "../../components/modal/DeletedModal.js";
import { Loader } from "../../components/loader/ButtonLoader.js";
import { add_company_parent_profile, company_parent_delete, company_parent_getby_id, company_parent_update, User_email_existance } from "../../service/client_api.js";
import Breadcrumb from "../../components/breadcrumbs/BreadCrumb.js";

const Company_member_add = ()=> {
    const { id } = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const[accountCreated,setAccountCreated] = useState(false)
    const [emailExists, setEmailExists] = useState(false);
    const[staffID,setStaffID] = useState(null)
    const [isupdate, setupdate] = useState(false);
    const [changepassword, setchangepassword] = useState(true);
    const [passwordStrength, setPasswordStrength] = useState("Weak");
    const [password8, setPassword8] = useState(false);
    const [passwordmatch, setpasswordmatch] = useState(false);
    const [passwordspc, setpasswordspc] = useState(false);
    const [passwordcg, setpasswordcg] = useState(false);
    const [errorCount, setErrorCount] = useState(0);
    const[userName ,setUserName]= useState("")
  const[deletedModal,setDeletedModal] = useState(false)
  const options1 = [
    {
      img: "/img/Group_grey.svg",
          activeImg: "/img/Group122.svg",   
      label: "All profiles",
      active: false,
      url: "/company_profile_list_table"
    },
    { label:id ? userName:"Add Member", active: true },
  ];
  
    const handlePasswordChange = (e) => {
      const password = e.target.value;
      setpasswordcg(true);
  
      let count = 0;
  
      if (password.length < 8) {
        count++;
        setPassword8(true);
      } else {
        setPassword8(false);
      }
  
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        count++;
        setpasswordspc(true);
      } else {
        setpasswordspc(false);
      }
  
      if (password === values.Password || password === values.Confirmpassword) {
       
  
        setpasswordmatch(false);
      } else {
        count++;
        setpasswordmatch(true);
  
      }
      setErrorCount(count);
      if (count === 0) {
        setPasswordStrength("Strong");
      } else if (count === 1) {
        setPasswordStrength("Moderate");
      } else {
        setPasswordStrength("Weak");
      }
    };
  
  
    const name = "";
    const {
      handleSubmit,
      setValues,
      setFieldValue,
      handleBlur,
      values,
      errors,
      touched,
      getFieldProps,
    } = useFormik({
      initialValues: {
        AccountType: "3",
        Firstname: "",
        Lastname: "",
        Email: "",
        Phonenumber: "",
        Password: "",
        Confirmpassword: "",
        IsNoSuPeAchPoQu: 0,
        IsNCourseExNewsProm: 0,
        IsLookFeedback: 0,
      },
      validationSchema: Yup.object({
        Firstname: Yup.string()
      .required("First name is required")
      .min(2, "First name must be at least 2 characters")
      .max(50, "First name must be at most 50 characters")
      .matches(/^(?!.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-\/]{3}).*$/, "Special characters are not allowed in sequence"),
  
    Lastname: Yup.string()
      .required("Last name is required")
      .min(2, "Last name must be at least 2 characters")
      .max(50, "Last name must be at most 50 characters")
      .matches(/^(?!.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-\/]{3}).*$/, "Special characters are not allowed in sequence"),
        Email: Yup.string()
          .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Invalid email address"
          )
          .required("Email is required"),
        Phonenumber: Yup.string().matches(/^[0-9]+$/, "Phone number must contain only numbers").notRequired(),
        ...(isupdate
          ? {
            Password: Yup.string().test(
              'is-password-required',
              'Password is required',
              function (value) {
                const { Confirmpassword } = this.parent;
                return (!value && !Confirmpassword) || (value && value.trim() !== '');
              }
            ),
            Confirmpassword: Yup.string().test(
              'is-confirm-password-required',
              'Confirm password is required',
              function (value) {
                const { Password } = this.parent;
                return (!value && !Password) || (value && value.trim() !== '');
              }
            ).oneOf([Yup.ref('Password'), null], 'Passwords must match')
          }
          : {
              Password: Yup.string().required("Password is required"),
              Confirmpassword: Yup.string()
                .required("Confirm password is required")
                .oneOf([Yup.ref("Password"), null], "Passwords must match"),
            }),
      }),
      onSubmit: async (values) => {
        if (isSubmitting) {
          return;
        }
        setIsSubmitting(true);
        // Check if password meets the strength criteria
       
      
        // Your existing submission logic
        if (isupdate) {
          if(values.Password || values.Confirmpassword){
            if(passwordStrength === "Strong" && !passwordmatch && !passwordspc && !emailExists){
              const data = {
                AccountType:values.AccountType,
                FirstName: values.Firstname,
                LastName: values.Lastname,
                Email: values.Email,
                Password: values.Password,
                PhoneNumber: values.Phonenumber,
                IsNoSuPeAchPoQu: values.IsNoSuPeAchPoQu,
                IsNCourseExNewsProm: values.IsNCourseExNewsProm,
                IsLookFeedback: values.IsLookFeedback,
                }
              const res = await company_parent_update(data, id);
              if (res.status === 200) {
                toast.success("Parent Update successfully", {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                setTimeout(() => {
                  window.location.href = "/company_profile_list_table";
                  setIsSubmitting(false);
                }, 3000);
              } else {
                toast.error(res.response.data.message ? res.response.data.message : 'Something went wrong', {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                setIsSubmitting(false);
              }
            }
            else {
              // toast.error("Something went wrong", {
              //   position: "bottom-right",
              //   autoClose: 5000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              //   theme: "light",
              // });
            }
  
          }else {
            const data = {
              AccountType:values.AccountType,
              FirstName: values.Firstname,
              LastName: values.Lastname,
              Email: values.Email,
              PhoneNumber: values.Phonenumber,
              IsNoSuPeAchPoQu: values.IsNoSuPeAchPoQu,
              IsNCourseExNewsProm: values.IsNCourseExNewsProm,
              IsLookFeedback: values.IsLookFeedback,
              }
              if(!emailExists){const res = await company_parent_update(data, id);
                if (res.status === 200) {
                  toast.success("Parent Update successfully", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  setTimeout(() => {
                    window.location.href = "/company_profile_list_table";
                    setIsSubmitting(false);
                  }, 3000);
                } else {
                  toast.error(res.response.data.message ? res.response.data.message : 'Something went wrong', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  setIsSubmitting(false);
                }}else {
                
              }
              }
          
         
        } else {
          if(passwordStrength === "Strong" && !passwordmatch && !passwordspc && !emailExists){
            const res = await add_company_parent_profile(values);
            if (res.status === 200) {
              toast.success("Member added successfully", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setTimeout(() => {
                window.location.href = "/company_profile_list_table";
                setIsSubmitting(false);
              }, 3000);
            } else {
              toast.error(res.response.data.message ? res.response.data.message : 'Something went wrong', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setIsSubmitting(false);
            }
          }else { 
          //   toast.error("Something went wrong", {
          //   position: "bottom-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
        }
         
          
        }
      },
    });
    const getuserdata = async () => {
      const res = await company_parent_getby_id(id);
      console.log("res",res)
      setAccountCreated(true)
      if (res.status == 200) {
        setStaffID(res.data.Data.UserID)
        setFieldValue("Firstname", res.data.Data.FirstName);
        setFieldValue("Lastname", res.data.Data.LastName);
        setUserName(res.data.Data.FirstName + " " +res.data.Data.LastName)

        setFieldValue("Email", decryption(res.data.Data.Email));
        setFieldValue("Phonenumber", res.data.Data.PhoneNumber);
        if (changepassword) {
          // setFieldValue("Password", decryption(res.data.Data.Password));
          // setFieldValue("Confirmpassword", decryption(res.data.Data.Password));
        } else {
          setFieldValue("Password", "");
          setFieldValue("Confirmpassword", "");
        }
        setFieldValue("IsNoSuPeAchPoQu", res.data.Data.IsNoSuPeAchPoQu);
        setFieldValue("IsNCourseExNewsProm", res.data.Data.IsNCourseExNewsProm);
        setFieldValue("IsLookFeedback", res.data.Data.IsLookFeedback);
        // setupdate(true);
      }
    };
    useEffect(() => {
      if (id) {
        getuserdata();
      }
    }, [id]);
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [showConfirmBox, setShowConfirmBox] = useState(false)
  
    
  
  
   
   
  
    const checkEmailValidity = async () => {
      const email = values.Email.trim();
      const updatedStaffID =  (isupdate ? staffID : accountCreated ? staffID : 0)
      if (email) {
        const res = await User_email_existance(email , updatedStaffID );
        if (res?.response?.data?.statusCode === 1) {
          setEmailExists(true);
        } else {
          setEmailExists(false);
        }
      }
    };
    const deleteUser = async (eid) => {
      const res = await company_parent_delete(eid);
      if (res.status == 200 || res.status === 201) {
      
        toast.success('User deleted successfully', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // setTimeout(()=> {
        //   window.location.href="/account_list"
        // },3000)
      } else {
        toast.error(res.response.data.message ? res.response.data.message : 'Something went wrong', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    const handleDeleteConfirm = () => {
      deleteUser(id)
      setShowConfirmBox(false);
      setDeletedModal(true)
    };
    function capitalizeFirstLetter(string) {
      return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
    }
  
return (
    <AdminLayout>
      <Breadcrumb items={options1} />
         <div className="user_form m-inner-space">
      <ToastContainer />

      <Halmet title="Team" />
      <div className="left_part">
        <div className="user_icon sub-title">{values?.Firstname?.charAt(0)?.toUpperCase()}{values?.Lastname?.charAt(0)?.toUpperCase()}</div>
         <div className="user_name usn sub-title"> {capitalizeFirstLetter(values?.Firstname)} {capitalizeFirstLetter(values?.Lastname)}</div>
      </div>
      <div className="virtical_line"></div>
      <div className="user_form_block">
        <div className="padding_24">
          <div className="input_field_block d-flex w-100">
            <div className="d-flex flex-column w-100">
              <label for="firstname" className="form-label">
                First name
                {/* <span className="user_required_feild">*</span> */}
              </label>
              <input
                aria-labelledby="firstname"
                className="form-control"
                id="firstname"
                type="text"
                {...getFieldProps("Firstname")}
                value={values.Firstname}
                onChange={(e) =>
                  setFieldValue("Firstname", e.target.value.trimStart())
                }
                onBlur={handleBlur}
              />
              {touched.Firstname && errors.Firstname ? (
                <div className="error_txt  d-flex align-items-center"><img src="/img/warnIcon.svg" alt="" className="me-1" height={18} width={18} /> {errors.Firstname}</div>
              ) : null}
            </div>
            <div className="d-flex flex-column w-100">
              <label for="lastname" className="form-label">
                Last name
                {/* <span className="user_required_feild">*</span> */}
              </label>
              <input
                aria-labelledby="lastname"
                className="form-control"
                id="lastname"
                type="text"
                {...getFieldProps("Lastname")}
                value={values.Lastname}
                onChange={(e) =>
                  setFieldValue("Lastname", e.target.value.trimStart())
                }
                onBlur={handleBlur}
              />
              {touched.Lastname && errors.Lastname ? (
                <div className="error_txt  d-flex align-items-center"><img src="/img/warnIcon.svg" alt="" className="me-1" height={18} width={18} /> {errors.Lastname}</div>
              ) : null}
            </div>
          </div>
          <div className="input_field_block d-flex w-100 pt-3">
            <div className="d-flex flex-column w-100">
              <label for="email" className="form-label">
                Email
                {/* <span className="user_required_feild">*</span> */}
              </label>
              <input
                aria-labelledby="email"
                className="form-control"
                id="email"
                type="email"
                {...getFieldProps("Email")}
                value={values.Email}
                onChange={(e) => setFieldValue("Email", e.target.value.trim())}
                onBlur={(e) => {
                  checkEmailValidity(e);
                  handleBlur(e);
                }}
              />
              {touched.Email && errors.Email ? (
                <div className="error_txt  d-flex align-items-center"><img src="/img/warnIcon.svg" alt="" className="me-1" height={18} width={18} /> {errors.Email}</div>
              ) : null}
              {errors.Email == "" ||
                (emailExists && (
                <div className="error_txt  d-flex align-items-center"><img src="/img/warnIcon.svg" alt="" className="me-1" height={18} width={18} /> Email already exists</div>
                ))}
            </div>
            {/* <div className="d-flex flex-column w-100">
              <label for="phonenumber" className="form-label">
                Phone number
                <span className="user_optional_feild">(optional)</span>
              </label>
              <input
                aria-labelledby="phonenumber"
                className="form-control"
                id="phonenumber"
                type="tel"
                {...getFieldProps("Phonenumber")}
                value={values.Phonenumber}
                onChange={(e) => {
                  const input = e.target.value.trim().replace(/\D/g, ''); 
                  if (/^[0-9]*$/.test(input)) {
                    setFieldValue("Phonenumber", input);
                  } else {
                    setFieldValue("Phonenumber", ""); 
                  }
                }}
                onBlur={handleBlur}
              />
            </div> */}
          </div>
          {isupdate ? (
            <>
              {changepassword ? (
                <>
                  <div className="input_field_block d-flex w-100 pt-3">
                    <div className="d-flex flex-column w-100 ">
                      <label for="password" className="form-label">
                        Password
                      </label>
                      <div className="password_eye_block">
                        <input
                          aria-labelledby="password"
                          className="form-control p-r-3v passwordField w-100"
                          id="password"
                          type={`${showPassword ? "text" : "Password"}`}
                          {...getFieldProps("Password")}
                          value={values.Password}
                          onChange={(e) =>
                            {setFieldValue("Password", e.target.value.trim()); handlePasswordChange(e);}
                          }
                          onBlur={handleBlur}
                        />

                        <img
                          className="eye_icon"
                          src={`${showPassword ? "/img/eye_icon.svg" : "/img/eye_close.svg"}`}
                          alt="eye_icon"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </div>
                      {touched.Password && errors.Password ? (
                      <div className="error_txt  d-flex align-items-center"><img src="/img/warnIcon.svg" alt="" className="me-1" height={18} width={18} /> {errors.Password}</div>
                    ) : null}
                    </div>
                    <div className="d-flex flex-column w-100 ">
                      <label for="Confirmpassword" className="form-label">
                        Confirm password
                       
                      </label>
                      <div className="password_eye_block">
                       
                        <input
                      aria-labelledby="Confirmpassword"
                      className="form-control p-r-3v passwordField w-100"
                      id="Confirmpassword"
                      type={`${showCPassword ? "text" : "Password"}`}
                      {...getFieldProps("Confirmpassword")}
                      value={values.Confirmpassword}
                      onChange={(e) => {
                        setFieldValue("Confirmpassword", e.target.value.trim());
                        handlePasswordChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                        <img
                          className="eye_icon"
                          src={`${showCPassword ? "/img/eye_icon.svg" : "/img/eye_close.svg"}`}
                          alt="eye_icon"
                          onClick={() => setShowCPassword(!showCPassword)}
                        />
                      </div>
                      {touched.Confirmpassword && errors.Confirmpassword ? (
                      <div className="error_txt  d-flex align-items-center"><img src="/img/warnIcon.svg" alt="" className="me-1" height={18} width={18} /> {errors.Confirmpassword}</div>
                    ) : null}
                    </div>
                  </div>
                  {passwordcg && (
                    <div className="password_validation">
                      <div className="show_password_strength d-flex align-items-center">
                        <span className="show_password_strength_text message">
                          {passwordStrength}
                        </span>
                        <div className="strong_password d-flex">
                          <div
                            className="measure_scale scale_left"
                            style={{
                              backgroundColor:
                              errorCount >= 2 ? "yellow" : errorCount >= 1 ? "yellow" : passwordStrength === "Strong" && "green",
                            }}
                          ></div>
                          <div
                            className="measure_scale scale_center"
                            style={{
                              backgroundColor:
                              errorCount == 1 ? "yellow" : passwordStrength === "Strong" && "green",
                            }}
                          ></div>
                          <div
                            className="measure_scale scale_right"
                            style={{
                              backgroundColor:
                              passwordStrength === "Strong" ? "green" : "",
                            }}
                          ></div>
                        </div>
                      </div>
                      <div
                        className={`message ${
                          password8 ? "warning" : "check"
                        }  d-flex align-items-center`}
                      >
                        <img
                          src={`${
                            password8
                              ? "/img/Warning_icon.svg"
                              : "/img/Checkmark_icon.svg"
                          }`}
                          alt="validation_message"
                        />{" "}
                        <span className="validation_message">8 characters</span>
                      </div>
                      <div
                        className={`message  ${
                          passwordmatch ? "warning" : "check"
                        } d-flex align-items-center`}
                      >
                        <img
                          src={`${
                            passwordmatch
                              ? "/img/Warning_icon.svg"
                              : "/img/Checkmark_icon.svg"
                          }`}
                          alt="Warning_icon"
                        />{" "}
                        <span className="validation_message">
                          Password match
                        </span>
                      </div>
                      <div
                        className={`message ${
                          passwordspc ? "warning" : "check"
                        }  d-flex align-items-center`}
                      >
                        <img
                          src={`${
                            passwordspc
                              ? "/img/Warning_icon.svg"
                              : "/img/Checkmark_icon.svg"
                          }`}
                          alt="Warning_icon"
                        />{" "}
                        <span className="validation_message">
                          Use 1 character or symbol
                        </span>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <Button
                  className={"outline_semi_violet_button mt-4 "}
                  onClick={() => setchangepassword(!changepassword)}
                >
                  Change Password
                </Button>
              )}
            </>
          ) : (

            <>
            {accountCreated ? (<></>):(<>
    
              <div className="input_field_block d-flex w-100 pt-3">
                <div className="d-flex flex-column w-100 ">
                  <label for="password" className="form-label">
                    Password
                    {/* <span className="user_required_feild">*</span> */}
                  </label>
                  <div className="password_eye_block">
                    <input
                      aria-labelledby="password"
                      className="form-control p-r-3v passwordField w-100"
                      id="password"
                      type={`${showPassword ? "text" : "Password"}`}
                      {...getFieldProps("Password")}
                      value={values.Password}
                      onChange={(e) =>
                        {setFieldValue("Password", e.target.value.trim()); handlePasswordChange(e);}
                      }
                      onBlur={handleBlur}
                    />
                    
                    <img
                      className="eye_icon"
                        src={`${showPassword ? "/img/eye_icon.svg" : "/img/eye_close.svg"}`}
                      alt="eye_icon"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                  {touched.Password && errors.Password ? (
                      <div className="error_txt  d-flex align-items-center"><img src="/img/warnIcon.svg" alt="" className="me-1" height={18} width={18} /> {errors.Password}</div>
                    ) : null}
                </div>
                <div className="d-flex flex-column w-100 ">
                  <label for="Confirmpassword" className="form-label">
                    Confirm password
                    {/* <span className="user_required_feild">*</span> */}
                  </label>
                  <div className="password_eye_block">
                    <input
                      aria-labelledby="Confirmpassword"
                      className="form-control p-r-3v passwordField w-100"
                      id="Confirmpassword"
                      type={`${showCPassword ? "text" : "Password"}`}
                      {...getFieldProps("Confirmpassword")}
                      value={values.Confirmpassword}
                      onChange={(e) => {
                        setFieldValue("Confirmpassword", e.target.value.trim());
                        handlePasswordChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                   

                    <img
                      className="eye_icon"
                        src={`${showCPassword ? "/img/eye_icon.svg" : "/img/eye_close.svg"}`}
                      alt="eye_icon"
                      onClick={() => setShowCPassword(!showCPassword)}
                    />
                  </div>
                  {touched.Confirmpassword && errors.Confirmpassword ? (
                      <div className="error_txt  d-flex align-items-center"><img src="/img/warnIcon.svg" alt="" className="me-1" height={18} width={18} /> {errors.Confirmpassword}</div>
                    ) : null}
                </div>
              </div>
              {passwordcg && (
                <div className="password_validation">
                  <div className="show_password_strength d-flex align-items-center">
                    <span className="show_password_strength_text message">
                      {passwordStrength}
                    </span>
                    <div className="strong_password d-flex">
                      <div
                        className="measure_scale scale_left"
                        style={{
                          backgroundColor:
                          errorCount >= 2 ? "yellow" : errorCount >= 1 ? "yellow" : passwordStrength === "Strong" && "green",
                        }}
                      ></div>
                      <div
                        className="measure_scale scale_center"
                        style={{
                          backgroundColor:
                          errorCount == 1 ? "yellow" : passwordStrength === "Strong" && "green",
                        }}
                      ></div>
                      <div
                        className="measure_scale scale_right"
                        style={{
                          backgroundColor:
                          passwordStrength === "Strong" ? "green" : "",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    className={`message ${
                      password8 ? "warning" : "check"
                    }  d-flex align-items-center`}
                  >
                    <img
                      src={`${
                        password8
                          ? "/img/Warning_icon.svg"
                          : "/img/Checkmark_icon.svg"
                      }`}
                      alt="validation_message"
                    />{" "}
                    <span className="validation_message">8 characters</span>
                  </div>
                  <div
                    className={`message  ${
                      passwordmatch ? "warning" : "check"
                    } d-flex align-items-center`}
                  >
                    <img
                      src={`${
                        passwordmatch
                          ? "/img/Warning_icon.svg"
                          : "/img/Checkmark_icon.svg"
                      }`}
                      alt="Warning_icon"
                    />{" "}
                    <span className="validation_message">Password match</span>
                  </div>
                  <div
                    className={`message ${
                      passwordspc ? "warning" : "check"
                    }  d-flex align-items-center`}
                  >
                    <img
                      src={`${
                        passwordspc
                          ? "/img/Warning_icon.svg"
                          : "/img/Checkmark_icon.svg"
                      }`}
                      alt="Warning_icon"
                    />{" "}
                    <span className="validation_message">
                      Use 1 character or symbol
                    </span>
                  </div>
                </div>
             )}</>)}
            </>
          )}
        </div>

        <div className="email_block padding_24">
          <div className="col-sm-12 mt-4 mt-sm-0">
            <div className="user_email-section mt-3">
              <h4 className="sub-title"> Email notifications </h4>
              <div className="email_content">
                <div class="form-check">
                  <label for="flexCheckDefault1" className="input_cont">
                    <input
                      aria-labelledby="flexCheckDefault1"
                      id="flexCheckDefault1"
                      type="checkbox"
                      value={1}
                      checked={values.IsNoSuPeAchPoQu === 1}
                      onChange={() => {
                        if (values.IsNoSuPeAchPoQu == 1) {
                          setFieldValue("IsNoSuPeAchPoQu", 0);
                        } else {
                          setFieldValue("IsNoSuPeAchPoQu", 1);
                        }
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label
                    for="flexCheckDefault2"
                    className="form-check-label form-label"
                    htmlFor="flexCheckDefault1"
                  >
                    Get notified on student’s performance, achievements,
                    progress and quizzes
                  </label>
                </div>
                <div class="form-check">
                  <label for="flexCheckDefault2" className="input_cont">
                    <input
                      aria-labelledby="flexCheckDefault2"
                      id="flexCheckDefault2"
                      type="checkbox"
                      value={1}
                      checked={values.IsNCourseExNewsProm === 1}
                      onChange={() => {
                        if (values.IsNCourseExNewsProm == 1) {
                          setFieldValue("IsNCourseExNewsProm", 0);
                        } else {
                          setFieldValue("IsNCourseExNewsProm", 1);
                        }
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label
                    className="form-check-label form-label"
                    for="flexCheckDefault2"
                  >
                    Get notified on new courses, exciting news and promotions
                  </label>
                </div>
                <div class="form-check">
                  <label for="flexCheckDefault3" className="input_cont">
                    <input
                      aria-labelledby="flexCheckDefault3"
                      id="flexCheckDefault3"
                      type="checkbox"
                      value={1}
                      checked={values.IsLookFeedback === 1}
                      onChange={() => {
                        if (values.IsLookFeedback == 1) {
                          setFieldValue("IsLookFeedback", 0);
                        } else {
                          setFieldValue("IsLookFeedback", 1);
                        }
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label
                    className="form-check-label form-label"
                    for="flexCheckDefault3"
                  >
                    Get notified when our team is looking for a feedback
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="padding_24 pb-0 d-flex align-items-center justify-content-center justify-content-sm-between ps-2">
          {/* <Link to={"/admin_parent_form"}> */}
          {
            accountCreated &&(  <Button
                    className="simple_button"
                    onClick={() => {setShowConfirmBox(true)
                   }}
                    image={"/img/Delete_dark_purple.svg"}

                  >Delete

                  </Button>)
          }
        <div></div>
        {isupdate ? (
          <>
          {
            isSubmitting ? (<Loader/>):(<Button
              className={"semi_violet_button"}
              type={"submit"}
              onClick={handleSubmit}
            >
              Save
            </Button>)
          }
          </>
              
            ) : (
              <>
                {accountCreated ? (
                  <Button
                    className={"outline_semi_violet_button"}
                    type={"submit"} image={"/img/Edit.svg"}
                    onClick={() => setupdate(true)}
                  >
                    Edit
                  </Button>
                ) : (
                  <>
                {
                  isSubmitting ? (<Loader/>):( <Button
                    className={"semi_violet_button"}
                    type={"submit"}
                    onClick={handleSubmit}
                  >
                    {" "}
                    Create account
                  </Button>)
                }
                </>
                 
                )}
              </>
            )}
          {/* </Link> */}
        </div>
      {showConfirmBox && <Alert message="Are you sure you want to Delete Account?" showConfirmBox={showConfirmBox} setShowConfirmBox={setShowConfirmBox} onConfirm={handleDeleteConfirm}  />}
      {deletedModal && <DeletedModal deletedModal={deletedModal} setDeletedModal={setDeletedModal} redirectRoute="/company_profile_list_table"/>}
      </div>

    </div>
    </AdminLayout>
)
}
export default Company_member_add;