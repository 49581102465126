import { useNavigate } from "react-router-dom";
import Button from "../../components/button/Button"
import { AdminLayout } from "../../components/parent/AdminLayout"

export const Student_mode = () => {
    const navigate = useNavigate()
    const handleCreateButtonClick = () => {
        navigate("/parent_account", { state: { renderStudentProfile: true } });
      };
    return (
        <AdminLayout>
            <div className="course-grade-list-wrapper card_glow">
                <h1 className="title mb-4 text-center">What is student mode?</h1>
                <div className="row pt-4">
                    <div className="col-12 col-sm-6 gx-sm-4">
                        <div className="d-flex flex-column flex-sm-row gap-4 align-items-center parent_membership_box">
                            <img src="/img/parent_membership/s1.svg" alt="" />
                            <div>
                                <p className="black_heading text-center text-sm-start">Full transparency</p>
                                <p className="para text-center text-sm-start" >Experience learning from a child’s perspective. Gain valuable insights into their journey.</p>
                            </div>

                        </div>
                        <div className="d-flex flex-column flex-sm-row gap-4 align-items-center parent_membership_box">
                            <img src="/img/parent_membership/s2.svg" alt="" />
                            <div>
                                <p className="black_heading text-center text-sm-start">Support learning</p>
                                <p className="para text-center text-sm-start">Access the same resources as your child. This lets you provide timely support whenever they need it. It also helps you have meaningful conversations about their progress. </p>
                            </div>

                        </div>


                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="d-flex flex-column flex-sm-row gap-4 align-items-center parent_membership_box">
                            <img src="/img/parent_membership/s3.svg" alt="" />
                            <div>
                                <p className="black_heading text-center text-sm-start">Stay informed</p>
                                <p className="para text-center text-sm-start">View a daily or weekly snapshot of your child's progress. It makes it easier to discuss their learning and encourage them.</p>
                            </div>

                        </div>
                        <div className="d-flex flex-column flex-sm-row gap-4 align-items-center parent_membership_box">
                            <img src="/img/parent_membership/s4.svg" alt="" />
                            <div>
                                <p className="black_heading text-center text-sm-start">Celebrate successes</p>
                                <p className="para pb-sm-4 text-center text-sm-start">Celebrate your child's milestones with badges, certificates, and achievements! These rewards not only boost their motivation but also pave the way for ongoing success. </p>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="d-flex justify-content-center align-items-center my-4">
                    <Button className={"semi_violet_button"} onClick={handleCreateButtonClick}>Create student account</Button>






                </div>




            </div>

        </AdminLayout>

    )
}