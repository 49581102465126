import axios from 'axios';
import { decryption, encryption } from './utils.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from "js-cookie";

const API_BASE = process.env.REACT_APP_BASE_URL;
const ADMIN_API = process.env.REACT_APP_ADMIN_BASE_URL;
const CLIENT_API = process.env.REACT_APP_CLIENT_BASE_URL;
const CLIENT_ACTIVITY_API = process.env.REACT_APP_CLIENT_ACTIVITY_URL
const token = Cookies.get("token");
const handleSignOut = () => {
    const Accounttype = Cookies.get("Accounttype");
    if (Accounttype === "Admin" || Accounttype === "Editor") {
        window.location.href = "/login";
    } else {
        window.location.href = "/user_login";
    }
    const itemsToRemove = [
        "token", "Accounttype", "staffPermission", "FirstName", "ParentInfo",
        "LastName", "selectedcourse", "messeging_token", "selectedCoursesName",
        "id2", "selectedStudentName", "selectedStudent", "memberOptions",
        "MyEditCourse", "MyEditModule", "SelectedLesson", "Data", "courseData",
        "students", "selectedButtons", "selectedStudentName", "selectedStudentId",
        "plannerCount", "selectedCourses", "CourseId", "ModulesID"
    ];

    itemsToRemove.forEach(item => localStorage.removeItem(item));

    Cookies.remove("token");
    Cookies.remove("Attendence");
    Cookies.remove("Accounttype");
    Cookies.remove("StaffId");

};
const clearAllCookies = () => {
    const allCookies = Cookies.get();
    for (let cookie in allCookies) {
        if (allCookies.hasOwnProperty(cookie)) {
            Cookies.remove(cookie);
        }
    }
};



export const Login_api = async (email, password, type) => {
    try {
        const requestData = {
            Email: encryption(email),
        };

        if (password) {
            requestData.Password = encryption(password);
        }

        if (type) {
            requestData.emailThere = type;
        }
        const response = await axios.post(ADMIN_API + "login", requestData);
        //console.log(response);
        return response;
    } catch (error) {
        return error;
    }
};

export const Help_add = async (data, selectedOption_) => {
    console.log(selectedOption_);
    const AccountType = selectedOption_ === "Company" ? 2 : selectedOption_ === "Parent" ? 3 : null;
    try {
        const formData = new FormData();
        formData.append('Title', data.qTitle);
        formData.append('AccountType', AccountType);
        formData.append('Category', data.category);
        formData.append('Description', data.description);
        formData.append("IsActive", 1)
        // formData.append('Question', data.Password);
        if (data.Thumbnail) { formData.append('ImgFile', data.Thumbnail); }
        if (data.Video) { formData.append('VideoFile', data.Video); }
        if (data.Subtitle) { formData.append('SubtitleFile', data.Subtitle); }




        const response = await axios.post(ADMIN_API + "help/add-help", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token}`
            }
        });
        //console.log(response);

        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Help_Update = async (data, selectedOption_, id, DeleteFiles, imgFile, videoFile, subtitleFile) => {
    const AccountType = selectedOption_ === "Company" ? 2 : selectedOption_ === "Parent" ? 3 : null;
    function isOnlyName(obj) {
        return Object.keys(obj).length === 1 && obj.hasOwnProperty('name');
    }

    try {
        const formData = new FormData();
        formData.append('Title', data.qTitle);
        formData.append('AccountType', AccountType);
        formData.append('Category', data.category);
        formData.append('Description', data.description);
        formData.append("IsActive", 1)
        if (DeleteFiles.length > 0) {
            formData.append('filesname', JSON.stringify(DeleteFiles));
        }
        // formData.append('Question', data.Password); 
        if (data.Thumbnail !== null) {
            formData.append('ImgFile', isOnlyName(data.Thumbnail) ? data.Thumbnail.name : data.Thumbnail);
        }
        if (data.Video !== null) {
            formData.append('VideoFile', isOnlyName(data.Video) ? data.Video.name : data.Video);
        }
        if (data.Subtitle !== null) {
            formData.append('SubtitleFile', isOnlyName(data.Subtitle) ? data.Subtitle.name : data.Subtitle);
        }

        const response = await axios.put(ADMIN_API + `help/update-help/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        });

        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Help_get = async (page, category) => {
    console.log(category);
    try {
        const encodedCategory = encodeURIComponent(category);
        const response = await axios.get(ADMIN_API + `help/get-helps/?${category && category !== "All" ? "Search=" + encodedCategory + "&" : ""}pageSize=100&${page ? "page=" + page + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Help_get_id = async (id) => {
    // console.log(data);
    try {
        const response = await axios.get(ADMIN_API + `help/get-help/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const delete_help_api = async (id) => {
    // console.log(data);
    try {
        const response = await axios.delete(ADMIN_API + `help/delete-help/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Help_get_category = async () => {
    // console.log(data);
    try {
        const response = await axios.get(ADMIN_API + `help/get-helps-category`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Event_get_id = async (id) => {
    // console.log(data);
    try {
        const response = await axios.get(ADMIN_API + `competition/get-competition/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Event_get = async (grade, page) => {
    console.log(grade);
    console.log(page);
    try {
        const response = await axios.get(ADMIN_API + `competition/get-competitions?${grade != "All" && grade ? "Search=" + grade + "&" : ""}pageSize=100&${page ? "page=" + page + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const delete_event_api = async (id) => {
    // console.log(data);
    try {
        const response = await axios.delete(ADMIN_API + `competition/delete-competition/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const users_get = async (search, type, status, page) => {

    console.log("accountTypeFilter", search, type, status, page);
    let updatedStatus = '';

    if(status !== "All"){
        updatedStatus = status === "Active" ? 'isActive=1' : status === "Inactive" ? 'isActive=0' : `status=${status}`;
    }
    const AccountType = type === "Student" ? 1 : type === "Company" ? 2 : type === "Parent" ? 3 : type === "Staff" ? 4 : null;
    // console.log(data);
    ;
    try {
        // const response = await axios.get(ADMIN_API + `Backend/Profile/profiles-get?pageSize=10&${page ? "page=" + page + "&" : ""}${search ? "Search=" + search + "&" : ""}${type && type != "All" ? "AccountType=" + type + "&" : ""}${status && status !== "All" ? "Status=" + (status === "Active" ? 1 : 0) + "&" : ""}`, {
        const response = await axios.get(ADMIN_API + `getUsers?${page ? "Page=" + page + "&" : ""}${search ? "Search=" + search + "&" : ""}${type && type != "All" ? "AccountType=" + AccountType + "&" : ""}&${updatedStatus}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const user_get_id = async (id) => {
    // console.log(data);
    ;
    try {
        const response = await axios.get(API_BASE + `Backend/Profile/profile-get/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const delete_user_api = async (id) => {
    // console.log(data);
    try {
        const response = await axios.delete(API_BASE + `Backend/Profile/profile-delete/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Staff_add = async (data) => {
    console.log(data);
    try {
        const response = await axios.post(API_BASE + "Staff/staff-add", {
            FirstName: data.FirstName,
            LastName: data.LastName,
            Email: data.Email,
            Password: data.Password,
            PhoneNumber: data.PhoneNumber,
            IsActive: data.IsActive,
            Token: `${token}`

        });
        //console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const Event_add = async (data) => {
    console.log(data);
    console.log(typeof (toString(data.Grade[0])));
    // for (let i = 0; i < data.Grade.length; i++) {
    // }
    try {

        const grades = data.Grade.flatMap(item => item.split(' / '));

        // Join the values into a single string
        const formattedGrades = grades.join(',');

        const formData = new FormData();
        formData.append('CompetitionDescription', data.Description);
        formData.append('CompetitionReason', data.Reason);
        formData.append('CompetitionRequirements', data.Requirements);
        formData.append('CompetitionTitle', data.Title);
        formData.append('Format', data.format);
        formData.append('Grade', `${formattedGrades}`);
        formData.append('FirstPrize', data._1st_place_prize);
        formData.append('SecondPrize', data._2nd_place_prize);
        formData.append('ThirdPrize', data._3rd_place_prize);
        formData.append('StartDate', new Date(data.Start_date).toISOString());
        formData.append('WinnerAnnouncementDate', new Date(data.AnnouncementDate).toISOString());
        formData.append('EndDate', new Date(data.Deadline).toISOString());
        if (data.Thumbnail) { formData.append('ImgFile', data.Thumbnail); }
        if (data.Video) { formData.append('VideoFile', data.Video); }
        if (data.Subtitle) { formData.append('SubtitleFile', data.Subtitle); }
        formData.append('IsActive', 1);

        const response = await axios.post(ADMIN_API + "competition/add-competition", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token}`
            }
        });
        //console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Event_update = async (data, id, DeleteFiles, imgFile, videoFile, subtitleFile) => {
    function isOnlyName(obj) {
        return Object.keys(obj).length === 1 && obj.hasOwnProperty('name');
    }

    try {
        const grades = data.Grade.flatMap(item => item.split(' / '));
        const formattedGrades = grades.join(',');
        const formData = new FormData();
        formData.append('CompetitionDescription', data.Description);
        formData.append('CompetitionReason', data.Reason);
        formData.append('CompetitionRequirements', data.Requirements);
        formData.append('CompetitionTitle', data.Title);
        formData.append('WinnerAnnouncementDate', new Date(data.AnnouncementDate).toISOString());
        formData.append('Format', data.format);
        formData.append('Grade', `${formattedGrades}`);
        formData.append('FirstPrize', data._1st_place_prize);
        formData.append('SecondPrize', data._2nd_place_prize);
        formData.append('ThirdPrize', data._3rd_place_prize);
        formData.append('StartDate', new Date(data.Start_date).toISOString());
        formData.append('EndDate', new Date(data.Deadline).toISOString());
        if (DeleteFiles.length > 0) {
            formData.append('filesname', JSON.stringify(DeleteFiles));
        }

        if (data.Thumbnail !== null) {
            formData.append('ImgFile', isOnlyName(data.Thumbnail) ? data.Thumbnail.name : data.Thumbnail);
        }
        if (data.Video !== null) {
            formData.append('VideoFile', isOnlyName(data.Video) ? data.Video.name : data.Video);
        }
        if (data.Subtitle !== null) {
            formData.append('SubtitleFile', isOnlyName(data.Subtitle) ? data.Subtitle.name : data.Subtitle);
        }

        formData.append('IsActive', 1);

        const response = await axios.put(ADMIN_API + `competition/update-competition/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: ` Bearer ${token}`
            }
        });
        //console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const delete_promo_module = async (moduleName, PromotionID, ModuleID, filename) => {
    const status = moduleName === "PromotionCarousel" ? 1 : moduleName === "PromotionVideo" ? 2 : moduleName === "PromotionOnboardingVideo" ? 3 : null;
    try {
        const response = await axios.delete(ADMIN_API + `promotion/PromotionMod?Status=${status ? status + "&" : ""}ModuleID=${ModuleID}&PromotionID=${PromotionID}`, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`
            },

        });
        console.log("response", response)
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const promo_add_api = async (data, subtitle, Video, Thumbnail) => {
    data.PromotionCarousel?.forEach((item, index) => {
        item.Position = index;
    });
    data.PromotionVideo?.forEach((item, index) => {
        item.Position = index;
    });
    data.PromotionOnboardingVideo?.forEach((item, index) => {
        item.Position = index;
    });
    console.log("data", data)
    console.log(data, subtitle, Video, Thumbnail);
    try {
        const formData = new FormData();
        if (data.type.type == "Student") {
            formData.append('PromotionTitle', data.AnnouncementBanner[0].PromotionTitle);
            formData.append('AccountType', 1);
            formData.append('CTA', data.AnnouncementBanner[0].CTA);
            formData.append('PromotionOnboardingVideo', JSON.stringify(data.PromotionOnboardingVideo));
            for (let i = 0; i < subtitle.length; i++) {
                formData.append('Subtitle', subtitle[i]);
            }
            for (let i = 0; i < Video.length; i++) {
                formData.append('Video', Video[i]);
            }
            for (let i = 0; i < Thumbnail.length; i++) {
                formData.append('Thumbnail', Thumbnail[i]);
            }
            formData.append('IsActive', 1);
        } else {

            formData.append('PromotionTitle', data.AnnouncementBanner[0].PromotionTitle);
            formData.append('AccountType', data.type.type === "Company" ? 2 : data.type.type === "Parent" ? 3 : "");
            formData.append('CTA', data.AnnouncementBanner[0].CTA);
            formData.append('MembershipBannerTitle', data.Membershipbanner[0].MembershipBannerTitle);
            formData.append('MembershipBannerCTA', data.Membershipbanner[0].MembershipBannerCTA);
            formData.append('MembershipBannerTag', data.Membershipbanner[0].MembershipBannerTag);
            formData.append('MembershipReferralTitle', data.Membershipreferral[0].MembershipReferralTitle);
            formData.append('MembershipReferralCTA', data.Membershipreferral[0].MembershipReferralCTA);
            formData.append('MembershipReferralTag', data.Membershipreferral[0].MembershipReferralTag);
            formData.append('MembershipReferralDescription', data.Membershipreferral[0].MembershipReferralDescription);
            formData.append('PromotionCarousel', JSON.stringify(data.PromotionCarousel));
            formData.append('PromotionVideo', JSON.stringify(data.PromotionVideo));
            formData.append('PromotionOnboardingVideo', JSON.stringify(data.PromotionOnboardingVideo));
            formData.append('IsActive', 1);
            for (let i = 0; i < subtitle.length; i++) {
                formData.append('Subtitle', subtitle[i]);
            }
            for (let i = 0; i < Video.length; i++) {
                formData.append('Video', Video[i]);
            }
            for (let i = 0; i < Thumbnail.length; i++) {
                formData.append('Thumbnail', Thumbnail[i]);
            }
        }


        const response = await axios.post(ADMIN_API + "promotion/add-promotion", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token}`
            }
        });
        //console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const promo_update_api = async (data, subtitle, video, thumbnail, id, DeleteFiles) => {
    console.log("data in api ", data, subtitle, video, thumbnail)
    data.PromotionCarousel?.forEach((item, index) => {
        item.Position = index;
    });
    data.PromotionVideo?.forEach((item, index) => {
        item.Position = index;
    });
    data.PromotionOnboardingVideo?.forEach((item, index) => {
        item.Position = index;
    });

    // console.log(data, subtitle, Video, Thumbnail);
    const ThumbnailName = [];
    const Thumbnail = [];

    thumbnail.forEach(item => {
        if (typeof item === 'string') {
            ThumbnailName.push(item);
        } else {
            Thumbnail.push(item);
        }
    });
    const VideoName = [];
    const Video = [];

    video.forEach(item => {
        if (typeof item === 'string') {
            VideoName.push(item);
        } else {
            Video.push(item);
        }
    });
    const SubtitleName = [];
    const Subtitle = [];

    subtitle.forEach(item => {
        if (typeof item === 'string') {
            SubtitleName.push(item);
        } else {
            Subtitle.push(item);
        }
    });
    try {
        const formData = new FormData();
        if (data.type.type == "Student") {
            formData.append('PromotionTitle', data.AnnouncementBanner[0].PromotionTitle);
            formData.append('AccountType', 1);
            formData.append('CTA', data.AnnouncementBanner[0].CTA);
            if (DeleteFiles.length > 0) {
                formData.append('DeleteFiles', JSON.stringify(DeleteFiles));
            }
            formData.append('PromotionOnboardingVideo', JSON.stringify(data.PromotionOnboardingVideo));
            if (Subtitle.length > 0) {
                for (let i = 0; i < Subtitle.length; i++) {
                    formData.append('Subtitle', Subtitle[i]);
                }
            }
            if (SubtitleName.length > 0) {
                for (let i = 0; i < SubtitleName.length; i++) {
                    formData.append('SubtitleName', SubtitleName[i]);
                }
            }
            if (VideoName.length > 0) {
                for (let i = 0; i < VideoName.length; i++) {
                    formData.append('VideoName', VideoName[i]);
                }
            }
            if (ThumbnailName.length > 0) {
                for (let i = 0; i < ThumbnailName.length; i++) {
                    formData.append('ThumbnailName', ThumbnailName[i]);
                }
            }
            if (Video.length > 0) {
                for (let i = 0; i < Video.length; i++) {
                    formData.append('Video', Video[i]);
                }
            }
            if (Thumbnail.length > 0) {
                for (let i = 0; i < Thumbnail.length; i++) {
                    formData.append('Thumbnail', Thumbnail[i]);
                }
            }
        } else {

            formData.append('PromotionTitle', data.AnnouncementBanner[0].PromotionTitle);
            formData.append('AccountType', data.type.type === "Company" ? 2 : data.type.type === "Parent" ? 3 : "");
            formData.append('CTA', data.AnnouncementBanner[0].CTA);
            formData.append('MembershipBannerTitle', data.Membershipbanner[0].MembershipBannerTitle);
            formData.append('MembershipBannerCTA', data.Membershipbanner[0].MembershipBannerCTA);
            formData.append('MembershipBannerTag', data.Membershipbanner[0].MembershipBannerTag);
            formData.append('MembershipReferralTitle', data.Membershipreferral[0].MembershipReferralTitle);
            formData.append('MembershipReferralCTA', data.Membershipreferral[0].MembershipReferralCTA);
            formData.append('MembershipReferralTag', data.Membershipreferral[0].MembershipReferralTag);
            formData.append('MembershipReferralDescription', data.Membershipreferral[0].MembershipReferralDescription);
            formData.append('PromotionCarousel', JSON.stringify(data.PromotionCarousel));
            formData.append('PromotionVideo', JSON.stringify(data.PromotionVideo));
            if (DeleteFiles.length > 0) {
                formData.append('DeleteFiles', JSON.stringify(DeleteFiles));
            }

            formData.append('PromotionOnboardingVideo', JSON.stringify(data.PromotionOnboardingVideo));
            if (Subtitle.length > 0) {
                for (let i = 0; i < Subtitle.length; i++) {
                    formData.append('Subtitle', Subtitle[i]);
                }
            }
            if (SubtitleName.length > 0) {
                for (let i = 0; i < SubtitleName.length; i++) {
                    formData.append('SubtitleName', SubtitleName[i]);
                }
            }
            if (VideoName.length > 0) {
                for (let i = 0; i < VideoName.length; i++) {
                    formData.append('VideoName', VideoName[i]);
                }
            }
            if (ThumbnailName.length > 0) {
                for (let i = 0; i < ThumbnailName.length; i++) {
                    formData.append('ThumbnailName', ThumbnailName[i]);
                }
            }
            if (Video.length > 0) {
                for (let i = 0; i < Video.length; i++) {
                    formData.append('Video', Video[i]);
                }
            }
            if (Thumbnail.length > 0) {
                for (let i = 0; i < Thumbnail.length; i++) {
                    formData.append('Thumbnail', Thumbnail[i]);
                }
            }
        }


        const response = await axios.put(ADMIN_API + `promotion/update-promotion/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token}`
            }
        });
        //console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const promo_get = async (id) => {

    // console.log(data)
    try {
        const response = await axios.get(ADMIN_API + `promotion/get-promotions/?AccountType=${id === "Company" ? 2 : id === "Parent" ? 3 : id === "Student" ? 1 : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const add_parent_profile = async (data) => {
    console.log(data);
    try {
        const response = await axios.post(ADMIN_API + "parent/", {
            // AccountType: data.AccountType,
            FirstName: data.Firstname,
            LastName: data.Lastname,
            Email: encryption(data.Email.toLowerCase()),
            Password: encryption(data.Password),
            PhoneNumber: data.Phonenumber,
            IsActive: 1,
            IsNoSuPeAchPoQu: data.IsNoSuPeAchPoQu,
            IsNCourseExNewsProm: data.IsNCourseExNewsProm,
            IsLookFeedback: data.IsLookFeedback,
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const update_parent_profile = async (data, id) => {
    console.log(data);
    try {
        const requestData = {

            // AccountType: data.AccountType,
            FirstName: data.FirstName,
            LastName: data.LastName,
            Email: encryption(data.Email.toLowerCase()),
            PhoneNumber: data.Phonenumber,
            IsActive: 1,
            IsNoSuPeAchPoQu: data.IsNoSuPeAchPoQu,
            IsNCourseExNewsProm: data.IsNCourseExNewsProm,
            IsLookFeedback: data.IsLookFeedback,
        };

        // Check if data.Password exists, if yes, add it to the request data
        if (data.Password) {
            requestData.Password = encryption(data.Password);
        }
        const response = await axios.put(ADMIN_API + `parent/${id}`, requestData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const add_student_profile = async (data, id) => {
    // const formattedAccountID = data.AccountID.toString().padStart(4, '0');
    console.log(data);
    try {
        const response = await axios.post(ADMIN_API + "student/", {
            // AccountID: formattedAccountID, 
            ParentID: id,
            // AccountType: data.AccountType,
            FirstName: data.Firstname,
            LastName: data.Lastname,
            Grade: data.Grade,

            Email: encryption(data.Email.toLowerCase()),
            Password: encryption(data.Password),
            PhoneNumber: data.Phonenumber,
            IsActive: 1,
            IsParticipateCompetitions: data.IsParticipateCompetitions,
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Admin_email_existance = async (email, id) => {
    try {
        const response = await axios.post(ADMIN_API + "existEmail", {
            Email: encryption(email),
            userID: id
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const http_Admin_email_existance = async (email, id) => {
    try {
        const response = await axios.post(ADMIN_API + "http_user_email", {
            Email: encryption(email),
            userID: id
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const update_student_profile = async (data, stid) => {
    // const formattedAccountID = data.AccountID.toString().padStart(4, '0');
    console.log(data);
    // console.log(id)
    console.log(stid)
    try {
        const requestData = {
            // AccountID: formattedAccountID, 
            // ParentID: id,

            Grade: data.Grade,
            // AccountType: data.AccountType,
            FirstName: data.FirstName,
            LastName: data.LastName,
            Email: encryption(data.Email.toLowerCase()),
            PhoneNumber: data.PhoneNumber,
            IsActive: 1,
            IsParticipateCompetitions: data.IsParticipateCompetitions,
        };

        // Check if data.Password exists, if yes, add it to the request data
        if (data.Password) {
            requestData.Password = encryption(data.Password);
        }
        const response = await axios.put(ADMIN_API + `student/${stid}`, requestData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const students_get = async (id) => {
    try {
        const response = await axios.get(ADMIN_API + `student/student_parent/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const add_Company_profile = async (data) => {
    console.log(data);
    try {
        const response = await axios.post(ADMIN_API + "company/", {
            // AccountType: data.AccountType,
            FirstName: data.Firstname,
            LastName: data.Lastname,
            TeamOrCompanyName: data.TeamOrCompanyName,
            TeamMember: data.TeamMember,
            Email: encryption(data.Email.toLowerCase()),
            Password: encryption(data.Password),
            PhoneNumber: data.Phonenumber,
            IsActive: "1",
            IsNCourseExNewsProm: data.IsNCourseExNewsProm,
            IsLookFeedback: data.IsLookFeedback,
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const update_Company_profile = async (data, id) => {
    console.log(data);
    try {
        const requestData = {

            // AccountType: data.AccountType,

            FirstName: data.FirstName,
            LastName: data.LastName,
            TeamOrCompanyName: data.TeamOrCompanyName,
            TeamMember: data.TeamMember,
            Email: encryption(data.Email.toLowerCase()),
            PhoneNumber: data.PhoneNumber,
            IsActive: "1",
            IsNCourseExNewsProm: data.IsNCourseExNewsProm,
            IsLookFeedback: data.IsLookFeedback,
        };

        // Check if data.Password exists, if yes, add it to the request data
        if (data.Password) {
            requestData.Password = encryption(data.Password);
        }
        const response = await axios.put(ADMIN_API + `company/${id}`, requestData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const add_staff_profile = async (data) => {
    console.log(data);
    const staffPermissionValue = data.StaffPermission === "Admin" ? "0" : "1"
    const AccountType = data.StaffPermission === "Admin" ? "4" : "5"
    try {
        const response = await axios.post(ADMIN_API + "staff/", {
            AccountType: AccountType,
            StaffPermission: staffPermissionValue,
            FirstName: data.FirstName,
            LastName: data.LastName,
            Email: encryption(data.Email.toLowerCase()),
            Password: encryption(data.Password),
            PhoneNumber: data.PhoneNumber,
            IsActive: "1",
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const update_Staff_profile = async (data, id) => {
    console.log(data);
    const AccountType = data.StaffPermission === "Admin" ? "4" : "5"
    const staffPermissionValue = data.StaffPermission === "Admin" ? "0" : "1";
    const requestData = {
        AccountType: AccountType,
        StaffPermission: staffPermissionValue,
        FirstName: data.FirstName,
        LastName: data.LastName,
        Email: encryption(data.Email.toLowerCase()),
        PhoneNumber: data.PhoneNumber,
        IsActive: "1",

    };
    if (data.Password) {
        requestData.Password = encryption(data.Password);
    }
    try {
        const response = await axios.put(ADMIN_API + `staff/${id}`, requestData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const course_get = async (page, category, grade, selectedStudent2) => {
    console.log("grade in api ", grade)
    console.log(category);
    try {
        const response = await axios.get(API_BASE + `Backend/Course/courses-get?${page ? "page=" + page + "&" : ""}pageSize=10&${category && category != "All" ? "CategoryName=" + category + "&" : ""}${grade && grade != "All" ? "Grade=" + grade + "&" : ""}${selectedStudent2 ? "Student=" + selectedStudent2 + "&" : ""}`, {
            headers: {
                "Authorization": `${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const course_detail_get = async (id) => {
    // console.log(data);
    try {
        const response = await axios.get(API_BASE + `Backend/Course/course-get/${id}`, {
            headers: {
                "Authorization": `${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Admin_course_by_id = async (id) => {
    try {
        const response = await axios.get(ADMIN_API + `course/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}

export const Course_add = async (data, subtitle, video, thumbnail, CourseImg, CourseVideo, CourseSubtitle, certificateImg, trophyImg) => {
    const dataWithPosition = { ...data };
    for (const key in dataWithPosition) {
        if (Array.isArray(dataWithPosition[key]) && dataWithPosition[key].length > 0 && key != "CourseCategories") {
            // If the value is an array with length greater than one, add 'Position' key to each object
            dataWithPosition[key].forEach((item, index) => {
                console.log("datadatadat", item)
                item.Position = index + 1;
                if (Array.isArray(item.CourseModuleLesson) && item.CourseModuleLesson.length > 0) {
                    item.CourseModuleLesson.forEach((lesson) => {
                        // Iterate over each property of the lesson object
                        for (const lessonKey in lesson) {
                            if (Array.isArray(lesson[lessonKey]) && lesson[lessonKey].length > 0) {
                                lesson[lessonKey].forEach((innerItem, index) => {
                                    innerItem.Position = index + 1;
                                });
                            }
                        }
                    });
                }
            });
        }
    }
    console.log("datadatadat", dataWithPosition);
    // data?.Module?.forEach(item => {
    //     delete item.video;
    //     delete item.Thumbnail;
    //     delete item.Subtitles;
    // });
    data?.Module?.forEach(item => {
        item.CourseModuleLesson?.forEach(less => {
            less.CourseModuleLessonQuiz?.forEach(q => {
                if (q.Answers && q.Answers[0] instanceof File) {
                    delete q.Answers;
                }
            });
        });
    });
    console.log("data in api", data, subtitle, video, thumbnail);
    console.log("data.module", data.Module.Thumbnail, data.Module)

    try {
        const formData = new FormData();
        formData.append('courseDesc', data.Course[0].Description);

        formData.append('courseTitle', data.Course[0].Title);
        formData.append('courseCategory', JSON.stringify(data.CourseCategories));
        formData.append('courseTag', data.Course[0].Tag);
        formData.append('courseGrade', JSON.stringify(data.Course[0].Grade));
        formData.append('IsParentCatolog', data.Course[0].isParentCatelog);
        formData.append('certificateTitle', data.Certificate[0].Title);
        formData.append('trophyTitle', data.Trophy[0].Title);
        formData.append('trophyDesc ', data.Trophy[0].Description);
        formData.append('CourseModule', JSON.stringify(data.Module));
        formData.append('courseImage', CourseImg);
        formData.append('courseVideo', CourseVideo);
        formData.append('courseSubtitle', CourseSubtitle);
        formData.append('certificateImage', certificateImg);
        formData.append('trophyImage', trophyImg);


        for (let i = 0; i < subtitle.length; i++) {
            formData.append('Subtitle', subtitle[i]);
        }
        for (let i = 0; i < video.length; i++) {
            formData.append('Video', video[i]);
        }
        for (let i = 0; i < thumbnail.length; i++) {
            formData.append('Thumbnail', thumbnail[i]);
        }
        formData.append('IsActive', 1);
        const response = await axios.post(ADMIN_API + "addCourse", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `${token}`
            }
        });
        //console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const Course_delete = async (id) => {
    try {
        const response = await axios.put(ADMIN_API + `deleteCourse/${id}`, {}, {
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`

            }
        })
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }

}
export const course_module_delete = async (moduleId) => {
    try {
        const response = await axios.put(ADMIN_API + `deleteModule/${moduleId}`, {},
            {
                headers: {
                    'Authorization': `Bearer ${token}`,

                },

            })
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const course_sub_lesson_delete = async (section, modulesublessonId) => {
    try {
        let sectionname;
        switch (section) {
            case "coursemodulelessontopic":
                sectionname = "LessonTopic";
                break;
            case "coursemodulelessonportfolio":
                sectionname = "LessonPortfolio";
                break;
            case "coursemodulelessonbadge":
                sectionname = "LessonBadge";
                break;
            default:
                sectionname = "LessonQuiz";
        }

        const data = {
            [sectionname]: Number(modulesublessonId)
        };

        const response = await axios.put(
            `${ADMIN_API}deleteLessonParts`,
            data,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Lesson_delete = async (id) => {
    try {
        const response = await axios.put(ADMIN_API + `deleteLessons/${id}`, {}, {
            headers: {
                authorization: `Bearer ${token}`
            }

        })
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const Category_get = async () => {
    try {
        const response = await axios.get(API_BASE + `Backend/Category/categorys-get?page=1&pageSize=10`, {
            headers: {
                "Authorization": `${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const Category_get_id = async (id) => {
    try {
        const response = await axios.get(API_BASE + `Backend/Category/category-get/${id}`, {
            headers: {
                "Authorization": `${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}

export const Category_add = async (category) => {
    try {
        const response = await axios.post(API_BASE + "Backend/Category/category-add", {
            CategoryName: category,
            IsActive: 1,



        }, {
            headers: {

                Authorization: `${token}`
            }
        });
        //console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}

export const Category_update = async (data, id) => {
    try {
        const response = await axios.post(API_BASE + `Backend/Category/category-update/${id}`, {
            CategoryName: data.Category,
            IsActive: 1,

            Token: `${token}`

        });
        //console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}

export const Pathway_Add = async (data, subtitle, Video, Thumbnail, PathwayTitle, courses, pathwayImg, pathwayVideo, pathwaySubtitle, certificateImg, trophyImg) => {
    console.log("courses", courses, "courses", JSON.stringify(courses), typeof (courses))
    courses = courses.map(course => String(course));
    console.log("courses", courses)
    console.log("data.grade", data.Grade)
    try {
        const formData = new FormData();

        formData.append('PathwayTitle', data.PathwayTitle);
        formData.append('PathwayTag', data.PathwayTag);
        formData.append('PathwayCategory', data.PathwayCategory);
        formData.append('PathwayDescription', data.PathwayDescription);
        formData.append('IsParentCatolog', data.IsParentCatolog);
        formData.append('CertificateTitle', data.CertificateTitle);
        formData.append('Grade', `${data.Grade.join(',')}`);
        formData.append('TrophyTitle', data.TrophyTitle);
        formData.append('Courses', `${courses.join(',')}`);
        formData.append('Title', PathwayTitle);
        if (data.cpThumbnail !== null) {
            formData.append('courseImage', data.cpThumbnail);
        } if (data.cpVideo !== null) {
            formData.append('courseVideo', data.cpVideo);
        } if (data.cpSubtitle !== null) {
            formData.append('courseSubtitle', data.cpSubtitle)
        } if (data.cThumbnail !== null) {
            formData.append('certificateImage', data.cThumbnail);
        } if (data.tThumbnail !== null) {
            formData.append('trophyImage', data.tThumbnail);
        }
        formData.append('Tag', "Learning path");

        formData.append('TrophyDescription', data.TrophyDescription);


        const response = await axios.post(ADMIN_API + "addPathway", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token}`
            }
        });
        //console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Pathway_Update = async (data, subtitle, Video, Thumbnail, DeleteFiles, id, PathwayTitle, courses, pathwayImg, pathwayVideo, pathwaySubtitle, certificateImg, trophyImg) => {

    function isOnlyName(obj) {
        return Object.keys(obj).length === 1 && obj.hasOwnProperty('name');
    }
    try {
        const formData = new FormData();


        formData.append('PathwayTitle', data.PathwayTitle);
        formData.append('PathwayTag', data.PathwayTag);
        formData.append('PathwayCategory', data.PathwayCategory);
        formData.append('PathwayDescription', data.PathwayDescription);
        formData.append('IsParentCatolog', data.IsParentCatolog);
        formData.append('CertificateTitle', data.CertificateTitle);
        formData.append('Grade', `${data.Grade.join(',')}`);
        formData.append('TrophyTitle', data.TrophyTitle);
        formData.append('Courses', `${courses.join(',')}`);
        formData.append('Title', PathwayTitle);
        if (DeleteFiles.length > 0) {
            formData.append('DeleteFiles', JSON.stringify(DeleteFiles));
        }

        if (data.cpThumbnail !== null) {
            if (isOnlyName(data.cpThumbnail)) {
                formData.append('courseImage', data.cpThumbnail.name)
            } else {
                formData.append('Thumbnail', data.cpThumbnail)
            }
        }
        if (data.cpVideo !== null) {
            if (isOnlyName(data.cpVideo)) {
                formData.append('courseVideo', data.cpVideo.name)
            } else {
                formData.append('Video', data.cpVideo)
            }
        }
        if (data.cpSubtitle !== null) {
            if (isOnlyName(data.cpSubtitle)) {
                formData.append('courseSubtitle', data.cpSubtitle.name)
            } else {
                formData.append('Subtitle', data.cpSubtitle)
            }
        }
        if (data.cThumbnail !== null) {
            if (isOnlyName(data.cThumbnail)) {
                formData.append('certificateImage', data.cThumbnail.name)
            } else {
                formData.append('Thumbnail', data.cThumbnail)
            }
        }
        if (data.tThumbnail !== null) {
            if (isOnlyName(data.tThumbnail)) {
                formData.append('trophyImage', data.tThumbnail.name)
            } else {
                formData.append('Thumbnail', data.tThumbnail)
            }
        }



        formData.append('Tag', "Learning path");

        formData.append('TrophyDescription', data.TrophyDescription);

        const response = await axios.put(ADMIN_API + `updatePathway/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token}`
            }
        });
        //console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Pathway_Get_id = async (id) => {
    try {

        const response = await axios.get(ADMIN_API + `pathway/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        //console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const pathways_get = async (page, category, grade) => {
    console.log(category);
    try {
        const response = await axios.get(API_BASE + `Backend/CoursePathway/pathways-get?${page ? "page=" + page + "&" : ""}pageSize=10&${category ? "CategoryName=" + category + "&" : ""}${grade ? "Grade=" + grade + "&" : ""}`, {
            headers: {
                "Authorization": `${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const company_help_get_category = async () => {
    // console.log(data);
    try {
        const response = await axios.get(API_BASE + `Backend/Company/company-helps-category-get`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};






export const parent_help_get_category = async () => {
    // console.log(data);
    try {
        const response = await axios.get(API_BASE + `Backend/Parent/parent-helps-category-get`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};


export const parent_get_id = async (id) => {
    // console.log(data);
    ;
    try {
        const response = await axios.get(API_BASE + `Backend/Parent/parent-get/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Front_end_Course_get = async (id) => {
    // console.log(data);

    try {
        const response = await axios.get(API_BASE + `Frontend/Course/courses-get`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Parent_student_get = async (id) => {
    // console.log(data);

    try {
        const response = await axios.get(API_BASE + `Backend/Parent/parent-student-get/73`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};


export const pathway_Category_get = async () => {
    try {
        const response = await axios.get(API_BASE + `Backend/CoursePathway/pathways-category-get`, {
            headers: {
                "Authorization": `${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}


export const update_planner = async (form, id2) => {
    console.log(form);
    form.Courses = form.Courses.map(course => String(course));
    form.Lessons = form.Lessons.map(lesson => String(lesson));

    try {
        const formData = new FormData();
        formData.append('StudentID', form.StudentID);
        if (form.Grade) {
            formData.append('Grade', form.Grade);
        }
        formData.append('Courses', form.Courses);
        formData.append('Lessons', form.Lessons);
        // formData.append('Courses',JSON.stringify(form.Courses));
        // formData.append('Lessons', JSON.stringify(form.Lessons));
        formData.append('LearningStyle', form.LearningStyle);
        formData.append('KnowledgeCheck', form.KnowledgeCheck);
        // formData.append('StartDate', form.StartDate.toISOString());
        formData.append('BreakTime', "12:55:30");
        formData.append('Monday', form?.Monday?.format('HH:mm:ss'));
        formData.append('Tuesday', form?.Tuesday?.format('HH:mm'));
        formData.append('Wednesday', form?.Wednesday?.format('HH:mm'));
        formData.append('Friday', form?.Friday?.format('HH:mm'));
        formData.append('Saturday', form?.Saturday?.format('HH:mm'));
        formData.append('Sunday', form?.Sunday?.format('HH:mm'));
        formData.append('IsActive', 1);


        const response = await axios.put(API_BASE + `Backend/Parent/parent-student-flow-update/${id2}`, {
            StudentID: form.StudentID,
            Grade: form.Grade,
            Courses: form.Courses,
            Lessons: form.Lessons,
            // ('Courses',JSON.stringify(form.Courses));
            // ('Lessons', JSON.stringify(form.Lessons));
            IsCustomize: form.isCustomize,
            LearningStyle: form.LearningStyl119,
            KnowledgeCheck: form.KnowledgeCheck,
            StartDate: form.StartDate instanceof Date ? form.StartDate.toISOString() : form.StartDate,
            BreakTime: "12:55:30",
            Monday: form?.Monday?.format('HH:mm:ss'),
            MondayEnd: form?.MondayEnd?.format('HH:mm:ss'),
            Tuesday: form?.Tuesday?.format('HH:mm:ss'),
            TuesdayEnd: form?.TuesdayEnd?.format('HH:mm:ss'),

            Wednesday: form?.Wednesday?.format('HH:mm:ss'),
            WednesdayEnd: form?.WednesdayEnd?.format('HH:mm:ss'),

            Thursday: form?.Thursday?.format('HH:mm:ss'),
            ThursdayEnd: form?.ThursdayEnd?.format('HH:mm:ss'),
            Friday: form?.Friday?.format('HH:mm:ss'),
            FridayEnd: form?.FridayEnd?.format('HH:mm:ss'),
            Saturday: form?.Saturday?.format('HH:mm:ss'),
            SaturdayEnd: form?.SaturdayEnd?.format('HH:mm:ss'),
            Sunday: form?.Sunday?.format('HH:mm:ss'),
            SundayEnd: form?.SundayEnd?.format('HH:mm:ss'),
            IsActive: 1,
        }, {
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                authorization: `${token}`
            }
        });
        //console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const newcourse_get = async () => {
    try {
        const response = await axios.get(API_BASE + `Backend/Course/New-courses-get`, {
            headers: {
                "Authorization": `${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const get_flow_id = async (id) => {
    try {
        const response = await axios.get(API_BASE + `Backend/Parent/flow-get/${id}`, {
            headers: {
                "Authorization": `${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}

export const reflection_add = async (data) => {
    console.log(data)
    try {
        const response = await axios.post(ADMIN_API + `reflection/add-reflection`, {
            Author: data.Author,
            Quote: data.Quote,

        }, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`
            }
        }); return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const reflection_update = async (data, id) => {
    try {
        const response = await axios.put(ADMIN_API + `reflection/update-reflection/${id}`, {
            Author: data.Author,
            Quote: data.Quote,

        }, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`
            }
        }); return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const reflections_get = async (page) => {
    // console.log(data);
    ;
    try {
        const response = await axios.get(ADMIN_API + `reflection/get-reflections/?pageSize=10&${page ? "page=" + page + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const reflection_get_id = async (id) => {
    // console.log(data);
    try {
        const response = await axios.get(ADMIN_API + `reflection/get-reflection/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const delete_reflection_api = async (id) => {
    // console.log(data);
    try {
        const response = await axios.delete(ADMIN_API + `reflection/delete-reflection/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const st_Event_get = async () => {
    try {
        const response = await axios.get(API_BASE + `Backend/Student/student-competition-get`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};


export const coursesAndPathways_get = async (page, category, grade) => {
    console.log("grade in api ", grade)
    console.log(category);
    try {
        const response = await axios.get(ADMIN_API + `getCourseData?${page ? "pageNo=" + page + "&" : ""}${category !== "All" ? "courseCategory=" + category + "&" : ""}${grade ? "courseGradeParams=" + grade + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Admin_courses_get = async (page, category, grade) => {
    try {
        const response = await axios.get(ADMIN_API + `forCourse?${page ? "Page=" + page + "&" : ""}${category && category != "All" ? "Category=" + category + "&" : ""}${grade && grade != "All" ? "Grade=" + grade + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};


export const courseAndPathway_category_get = async () => {
    try {
        const response = await axios.get(ADMIN_API + `allCategories`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const CourseData_add = async (data, thumbnail, video, subtitle) => {
    const dataWithPosition = { ...data };
    console.log(`Tlhkjcsdj`, data);
    for (const key in dataWithPosition) {
        if (Array.isArray(dataWithPosition[key]) && dataWithPosition[key].length > 0 && key != "CourseCategories") {
            dataWithPosition[key].forEach((item, index) => {
                console.log("datadatadat", item)
                item.position = index;
                if (Array.isArray(item.CourseModuleLesson) && item.CourseModuleLesson.length > 0) {
                    item.CourseModuleLesson.forEach((lesson) => {
                        for (const lessonKey in lesson) {
                            if (Array.isArray(lesson[lessonKey]) && lesson[lessonKey].length > 0) {
                                lesson[lessonKey].forEach((innerItem, index) => {
                                    innerItem.position = index;
                                });
                            }
                        }
                    });
                }
            });
        }
    }
    console.log("data.Course[0].Grade", data.Course[0].Grade)
    try {
        const formattedGrades = [];
        data.Course[0].Grade.forEach(grade => {
            const numbers = grade.split(' / ');
            formattedGrades.push(...numbers);
        });

        const formData = new FormData();
        formData.append('courseDesc', data.Course[0].Description);

        formData.append('courseTitle', data.Course[0].Title);
        formData.append('courseCategory', JSON.stringify(data.CourseCategories));
        formData.append('courseTag', data.Course[0].Tag);
        formData.append('courseGrade', JSON.stringify(formattedGrades));
        formData.append('IsParentCatolog', data.Course[0].isParentCatelog);
        formData.append('comingSoon', data.Course[0].comingSoon);
        formData.append('certificateTitle', data.Certificate[0].Title);
        formData.append('trophyTitle', data.Trophy[0].Title);
        formData.append('trophyDesc', data.Trophy[0].Description);
        formData.append('CourseModule', JSON.stringify(data.Module));

        if (subtitle.length > 0) {
            // if (subtitle.length === 1) {
            //     formData.append('Subtitle', `[${subtitle[0]}]`);
            // } else {

            for (let i = 0; i < subtitle.length; i++) {
                formData.append('Subtitle', subtitle[i]);
            }
            // }

        }

        if (video.length > 0) {
            // if (video.length === 1) {
            //     formData.append('Video', `[${video[0]}]`);
            // } else {
            for (let i = 0; i < video.length; i++) {
                formData.append('Video', video[i]);
            }
            // }
        }
        console.log("formdata", formData)
        if (thumbnail.length > 0) {
            // if (thumbnail.length === 1) {
            //     formData.append('Thumbnail', `[${thumbnail[0]}]`);
            // } else {
            for (let i = 0; i < thumbnail.length; i++) {
                formData.append('Thumbnail', thumbnail[i]);
            }
            // }
        }
        formData.append('IsActive', 1);
        const response = await axios.post(ADMIN_API + "addCourse", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token}`
            }
        });
        console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Course_update = async (data, subtitle, video, thumbnail, id, DeleteFiles, DeleteFiles1) => {
    const dataWithPosition = { ...data };
    for (const key in dataWithPosition) {
        if (Array.isArray(dataWithPosition[key]) && dataWithPosition[key].length > 0 && key != "CourseCategories") {
            // If the value is an array with length greater than one, add 'Position' key to each object
            dataWithPosition[key].forEach((item, index) => {
                console.log("datadatadat", item)
                item.position = index;
                if (Array.isArray(item.CourseModuleLesson) && item.CourseModuleLesson.length > 0) {
                    item.CourseModuleLesson.forEach((lesson) => {
                        // Iterate over each property of the lesson object
                        for (const lessonKey in lesson) {
                            if (Array.isArray(lesson[lessonKey]) && lesson[lessonKey].length > 0) {
                                lesson[lessonKey].forEach((innerItem, index) => {
                                    innerItem.position = index;
                                });
                            }
                        }
                    });
                }
            });
        }
    }
    const myeditModule = JSON.parse(localStorage.getItem('MyEditModule')) || [];
    data?.Module?.forEach(item => {
        // Remove the unwanted keys
        // delete item.Video;
        // delete item.Thumbnail;
        // delete item.Subtitle;

        // Find the corresponding module from myeditModule by matching the position
        const correspondingModule = myeditModule.find(mod => mod.modulePosition === item.position);

        // If a match is found, add the moduleID to the current module item
        if (correspondingModule) {
            item.CourseModuleID = correspondingModule.moduleID;
        }
    });
    const ThumbnailName = [];
    const Thumbnail = [];

    thumbnail.forEach(item => {
        if (typeof item === 'string') {
            ThumbnailName.push(item);
        } else {
            Thumbnail.push(item);
        }
    });
    const VideoName = [];
    const Video = [];

    video.forEach(item => {
        if (typeof item === 'string') {
            VideoName.push(item);
        } else {
            Video.push(item);
        }
    });
    const SubtitleName = [];
    const Subtitle = [];

    subtitle.forEach(item => {
        if (typeof item === 'string') {
            SubtitleName.push(item);
        } else {
            Subtitle.push(item);
        }
    });
    console.log("delete files here", DeleteFiles, DeleteFiles1);

    console.log("data in api", data, subtitle, video, thumbnail);
    console.log("data.module", data.Module.Thumbnail, data.Module)

    try {
        const formattedGrades = [];
        data.Course[0].Grade.forEach(grade => {
            const numbers = grade.split(' / ');
            formattedGrades.push(...numbers);
        });

        const formData = new FormData();
        formData.append('courseDesc', data.Course[0].Description);

        formData.append('courseTitle', data.Course[0].Title);
        formData.append('courseCategory', JSON.stringify(data.CourseCategories));
        formData.append('courseTag', data.Course[0].Tag);
        formData.append('courseGrade', JSON.stringify(formattedGrades));
        formData.append('IsParentCatolog', data.Course[0].isParentCatelog);
        formData.append('certificateTitle', data.Certificate[0].Title);
        formData.append('trophyTitle', data.Trophy[0].Title);
        formData.append('trophyDesc', data.Trophy[0].Description);
        formData.append('CourseModule', JSON.stringify(data.Module));
        if (DeleteFiles.length > 0) {
            formData.append('deletedModule', JSON.stringify(DeleteFiles));
        }
        if (DeleteFiles1.length > 0) {
            formData.append('deletedCourse', JSON.stringify(DeleteFiles1));
        }
        if (Subtitle.length > 0) {
            for (let i = 0; i < Subtitle.length; i++) {
                formData.append('Subtitle', Subtitle[i]);
            }
        }
        if (SubtitleName.length > 0) {
            for (let i = 0; i < SubtitleName.length; i++) {
                formData.append('SubtitleName', SubtitleName[i]);
            }
        }
        if (VideoName.length > 0) {
            for (let i = 0; i < VideoName.length; i++) {
                formData.append('VideoName', VideoName[i]);
            }
        }
        if (ThumbnailName.length > 0) {
            for (let i = 0; i < ThumbnailName.length; i++) {
                formData.append('ThumbnailName', ThumbnailName[i]);
            }
        }
        if (Video.length > 0) {
            for (let i = 0; i < Video.length; i++) {
                formData.append('Video', Video[i]);
            }
        }
        if (Thumbnail.length > 0) {
            for (let i = 0; i < Thumbnail.length; i++) {
                formData.append('Thumbnail', Thumbnail[i]);
            }
        }
        formData.append('IsActive', 1);
        const response = await axios.put(ADMIN_API + `editCourse/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token}`
            }
        });
        //console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const Lesson_add = async (data, CourseId, thumbnail, video, subtitle) => {
    data.CourseModuleLesson.forEach((item, index) => {
        item.position = index;
        ['LessonPortfolio', 'LessonBadge', 'LessonTopic', 'LessonQuiz'].forEach((key) => {
            if (Array.isArray(item[key])) {
                item[key].forEach((nestedItem, nestedIndex) => {
                    nestedItem.position = nestedIndex;
                });
            }
        });
    });

    console.log("data in api", data, CourseId, thumbnail, video, subtitle)
    const formData = new FormData();
    formData.append('LessonData', JSON.stringify(data.CourseModuleLesson));
    formData.append("courseId", CourseId)
    let SubtitleArray = [];
    if (subtitle.length > 0) {
        for (let i = 0; i < subtitle.length; i++) {
            formData.append('Subtitle', subtitle[i]);
            if (i !== 0) {
                SubtitleArray.push(subtitle[i]);
            }
        }
    }

    let VideoArray = [];

    if (video.length > 0) {
        for (let i = 0; i < video.length; i++) {
            formData.append('Video', video[i]);
            if (i !== 0) {
                VideoArray.push(video[i]);
            }
        }
    }

    let ThumbnailArray = [];

    if (thumbnail.length > 0) {
        for (let i = 0; i < thumbnail.length; i++) {
            formData.append('Thumbnail', thumbnail[i]);
            if (i !== 0) {
                ThumbnailArray.push(thumbnail[i]);
            }
        }
    }

    try {
        const response = await axios.post(ADMIN_API + "addLesson", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const Lesson_update = async (data, CourseId, thumbnail, video, subtitle, DeleteFiles) => {
    data.CourseModuleLesson.forEach((item, index) => {
        item.position = index;
        ['LessonPortfolio', 'LessonBadge', 'LessonTopic', 'LessonQuiz'].forEach((key) => {
            if (Array.isArray(item[key])) {
                item[key].forEach((nestedItem, nestedIndex) => {
                    nestedItem.position = nestedIndex;
                });
            }
        });
    });

    console.log("data in update api", data, CourseId, thumbnail, video, subtitle)
    const formData = new FormData();
    formData.append('LessonData', JSON.stringify(data.CourseModuleLesson));
    formData.append("courseId", CourseId)
    if (DeleteFiles.length > 0) {
        formData.append('deletedLesson', JSON.stringify(DeleteFiles));
    }
    let SubtitleArray = [];
    if (subtitle.length > 0) {
        for (let i = 0; i < subtitle.length; i++) {
            formData.append('Subtitle', subtitle[i]);
            if (i !== 0) {
                SubtitleArray.push(subtitle[i]);
            }
        }
    }
    let VideoArray = [];

    if (video.length > 0) {
        for (let i = 0; i < video.length; i++) {
            formData.append('Video', video[i]);
            if (i !== 0) {
                VideoArray.push(video[i]);
            }
        }
    }

    let ThumbnailArray = [];

    if (thumbnail.length > 0) {
        for (let i = 0; i < thumbnail.length; i++) {
            formData.append('Thumbnail', thumbnail[i]);
            if (i !== 0) {
                ThumbnailArray.push(thumbnail[i]);
            }
        }
    }

    try {
        const response = await axios.put(ADMIN_API + `editLesson/${data.CourseModuleLesson[0].CourseModuleLessonID}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const parent_profile_get_id = async (id) => {
    try {
        const response = await axios.get(ADMIN_API + `parent/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const delete_parent_profile = async (id) => {
    // console.log(data);
    try {
        const response = await axios.delete(ADMIN_API + `parent/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const student_profile_get_id = async (id) => {
    console.log("in student api")

    try {
        const response = await axios.get(ADMIN_API + `student/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const delete_student_profile = async (id) => {
    // console.log(data);
    try {
        const response = await axios.delete(ADMIN_API + `student/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const commpany_profile_get_id = async (id, page) => {
    // console.log(data);
    try {
        const response = await axios.get(ADMIN_API + `company/companyById?${id ? "UserID=" + id + "&" : ""}${page ? "page=" + page : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const delete_company_profile = async (id) => {
    // console.log(data);
    try {
        const response = await axios.delete(ADMIN_API + `company/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const staff_profile_get_id = async (id) => {
    // console.log(data);
    ;
    try {
        const response = await axios.get(ADMIN_API + `staff/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const delete_staff_profile = async (id) => {
    // console.log(data);
    try {
        const response = await axios.delete(ADMIN_API + `staff/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const get_dashboard_data = async (grade) => {
    try {
        const response = await axios.get(ADMIN_API + `dashboard/?${grade ? "gradeFilter=" + grade : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}

export const cms_columns = async (id) => {
    try {
        const response = await axios.get(CLIENT_API + `emailStructure/emailColumns?${id ? "emailType=" + id : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const update_email = async (id, data) => {
    console.log(data);

    try {
        const response = await axios.put(CLIENT_API + `emailStructure/editEmailColumns?${id ? "emailType=" + id : ""}`, { emailContent: data }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const get_report = async (search, type, startDate, endDate) => {
    try {
        const response = await axios.get(CLIENT_ACTIVITY_API + `report/filterReportAdmin?${search ? "studentName=" + search + "&" : ""}${type ? "reportType=" + type + "&" : ""}${startDate ? "startDate=" + startDate + "&" : ""}${endDate ? "endDate=" + endDate + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const get_Attendence_report = async (search) => {
    try {
        const response = await axios.get(CLIENT_ACTIVITY_API + `report/s/attendance?${search ? "studentSearch=" + search + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const download_report = async (student, type, startDate, endDate) => {
    try {
        const data = {
            studentId: student,
            reportType: type,
            StartDate: startDate,
            EndDate: endDate,
        };

        console.log("Request Data:", data);

        const response = await axios.post(
            `${CLIENT_ACTIVITY_API}report/downloadPdfAdmin`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );

        // Log and validate the response
        console.log("API Response:", response);
        if (response.status === 200) {
            return response; // Return the response if successful
        } else {
            throw new Error(`Unexpected status code: ${response.status}`);
        }
    } catch (error) {
        console.error("Error in download_report:", error);

        // Handle specific error cases
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            console.warn("Invalid token, signing out...");
            handleSignOut();
        } else if (error?.response?.data?.existStatus === 0) {
            console.warn("Exist status is 0, signing out...");
            handleSignOut();
        }

        // Return error to handle gracefully in the calling function
        return error.response || error;
    }
};



export const Reset_password = async (uniqueId, id) => {
    try {
        const response = await axios.get(ADMIN_API + `usersLogin/resetPassword?${uniqueId ? "userUniqueId=" + uniqueId + "&" : ""}${id ? "userId=" + id + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};







