import React, { useEffect } from "react";
import Button from "../button/Button";
import Modal from "./modal";

const Alert = ({
  message,
  showConfirmBox,
  setShowConfirmBox,
  onConfirm,
  id,

  onClose,
}) => {
  useEffect(() => {
    if (showConfirmBox) {
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "auto";
    }

    return () => {
      document.documentElement.style.overflowY = "auto";
    };
  }, [showConfirmBox]);

  return (
    <>
      {showConfirmBox && (
        <Modal>
          <div className="modal_user_confirmation p-3">
            <img
              src="/img/SVG-delete.svg"
              height={200}
              width={200}
              alt="delete"
            />

            <div className="black_heading">
              Are you sure you want to delete?
            </div>
            <div className="button-container">
              <Button
                className="outline_semi_violet_button px-3"
                onClick={() => {
                  onConfirm();
                }}
                image={"/img/Delete_light.svg"}
              >
                Delete
              </Button>
              <button
                className="semi_violet_button px-4"
                onClick={() => {
                  setShowConfirmBox(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Alert;
