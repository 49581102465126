import { useEffect, useState } from "react";

import { get_invoice_data, students_get } from "../../service/client_api";
import Select from "../../components/select/Select.js";
import Button from "../../components/button/Button.js";
import { AdminLayout } from "../../components/company/AdminLayout.js";
import { decryption } from "../../service/utils.js";
 const Company_billing = () => {
    const [invoiceData, setInvoiceData] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        get_Invoice_data()
    }, []);
   
    const get_Invoice_data = async () => {
        try {
            setLoading(true);
            const res = await get_invoice_data();
            if (res.data && res.data.data) {
                setInvoiceData(res.data.data);
                setLoading(false);
            } else {
                console.log("error while fetching data")
            }
        } catch (error) {
            console.error("Error fetching subscription data:", error);
            setLoading(false);
            // setSubscribedProductId("");
        }
    };
    const handleDownload = (pdfUrl, name) => {
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = `${name}_invoice.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <AdminLayout>
            <>
                {
                    invoiceData.length > 0 && (
                        <div className="course-grade-list-wrapper card_glow membership">
                            <h2 className="parent_page_title">What’s included in the plan</h2>
                            <div className="three_div_membership m-d-flex">
                                <ul>
                                    <li className="para"><span><img src="/img/membership/1.svg" alt="Access to 75+ courses" /></span>Access to 75+ courses</li>
                                    <li className="para"><span><img src="/img/membership/2.svg" alt="Portfolio projects" /></span>Portfolio projects</li>
                                    <li className="para"><span><img src="/img/membership/3.svg" alt="Competitions" /></span>Competitions</li>
                                    <li className="para"><span><img src="/img/membership/4.svg" alt="Certificates" /></span>Certificates</li>
                                    <li className="para"><span><img src="/img/membership/5.svg" alt="AcLearning advisor" /></span>AcLearning advisor</li>
                                    <li className="para"><span><img src="/img/membership/6.svg" alt="Achievement tracker" /></span>Achievement tracker</li>
                                    <li className="para"><span><img src="/img/membership/7.svg" alt="Course customization" /></span>Course customization</li>
                                    <li className="para"><span><img src="/img/membership/8.svg" alt="Downloadable reports" /></span>Downloadable reports</li>
                                    <li className="para"><span><img src="/img/membership/9.svg" alt="Customer support" /></span>Customer support</li>
                                </ul>
                                {
                                    invoiceData && invoiceData.length > 0 && invoiceData.map((item, index) => {
                                        return (
                                            <>
                                                <div className={`membership_monthly yellow_b }`}>
                                                    <div className="d-flex justify-content-between align-items-center">

                                                        <div className="membership_monthly_tag"><span className="para">{item.object}</span> </div>
                                                        {item.status !== null && (<span class="membership_monthly_tag offer"><span className="para">{item.status} </span></span>)}
                                                    </div>
                                                    <h2 className="parent_page_title mb-3">{item?.customer_name}</h2>
                                                    <p className="para mb-3"><span className="title">${item?.total / 100}</span></p>
                                                    <p className="para">{item.billing_reason} </p>
                                                    <Button image={"/img/down.svg"}
                                                        className={"outline_semi_violet_button mx-auto"} onClick={() => handleDownload(item.invoice_pdf, item.customer_name)}>Download</Button>
                                                </div>
                                            </>  
                              )})}
                            </div>
                        </div>
                    )
                }
                {
                    (!loading && invoiceData.length === 0) || invoiceData.length === 0 && (
                        <>
                            <div
                                className="course-grade-list-wrapper mt-4 card_glow thank_you_container"
                                height={400}
                            >
                                <img src="/img/searchforreportlatest.svg" alt=""
                                    width={220} />
                                <h1 className="violet_heading text-center font-16">There is not any invoice</h1>
                            </div>
                        </>
                    )
                }
            </>


        </AdminLayout>
    )
}
export default Company_billing;