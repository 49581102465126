import React, {useEffect } from "react";
import'./css/headline.css'
import Button from "../button/Button";
export const Headline = ({children , className , innclass,button , onClick}) => {
    return(
        <div className={`top-headline d-flex gap-3 ${className}`}>
             <p className={`para ${innclass}`}>{children}</p>
             {button === true && (
                 <Button className="para head-aqua-button" onClick={onClick} img2="/img/rarrowaqua.svg">Back to parent mode</Button>

             )}

        </div>
       
    )
}