import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup'
import { get_notification, notification_seen } from '../../../service/client_api';
export function Parent_notification() {
  const[notificationData,setNotificationData]= useState([])

  const getNotification = async()=> {
    try{
      const res = await get_notification()
      if(res && res.data && res.data.notificaions && res.data.notificaions.length > 0){
        setNotificationData(res.data.notificaions)
      }
      console.log("res of notification",res)
    }catch(error){
      console.log("error",error)
    }

  }
  useEffect(()=> {
    getNotification()
  },[])

  const [clickedItem, setClickedItem] = useState(null);

  const handleNotificationClick = async(id) => {
    try {
      const res = await notification_seen(id)
      console.log("res",res)

    }catch(error){
      console.log("error",error)
    }
    setClickedItem(id);
    setTimeout(() => {
      setNotificationData((prev) => prev.filter(notification => notification.id !== id));
      setClickedItem(null); 
    }, 3000); 
  };
  return (
    <div>
      <Popup
        className="pr-10 text-center"
        contentStyle={{
          width: "375px",
          backgroundColor: "white",
          cursor: "pointer",
          border: "1px solid #d9d9d9",
          borderRadius: "8px",
          padding: "10px",
          zIndex: "1010"
        }}
        trigger={
          <img
            src="/img/tabler_bell.svg"
            className="admin_layout_iconbell cursor_pointer"
            alt='bell'
          />
        }
        position="bottom right"
      >
        <h1 className="pop_heading mb-0 pop_div">Notifications</h1>
        <div className="line"></div>
        {notificationData  && notificationData.length > 0 &&  notificationData.map((notification, index) => (
          <React.Fragment key={notification.notificationId}>
            <div
              className={`d-flex pop_div gap-2 `}
              onClick={() => handleNotificationClick(notification.notificationId)}
            >
              {notification?.icon && <img src={notification?.icon} alt="icon" />}
              <p className={`texts ${clickedItem === notification.notificationId ? 'violet_text' : ''}`}>{notification.notificationTitle}</p>
            </div>
            {index < notificationData.length - 1 && <div className="line"></div>}
          </React.Fragment>
        ))}
        {
          notificationData.length === 0 &&(
            <div className='d-flex justify-content-center my-4 align-items-center '><p className='para'>No notifications yet.</p></div>
          )
        }
      </Popup>
    </div>
  )
}


