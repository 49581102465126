import Cookies from "js-cookie";
import { encryption } from "./utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const CLIENT_API = process.env.REACT_APP_CLIENT_BASE_URL;
const CLIENT_ACTIVITY_API = process.env.REACT_APP_CLIENT_ACTIVITY_URL;
const ADMIN_API = process.env.REACT_APP_ADMIN_BASE_URL;

const token = Cookies.get("token");
console.log("CLIENT_API", CLIENT_API)

const handleSignOut = () => {

    const Accounttype = Cookies.get("Accounttype");
    if (Accounttype === "Admin" || Accounttype === "Editor") {
        window.location.href = "/login";
    } else {
        window.location.href = "/user_login";
    }

    const itemsToRemove = [
        "token", "Accounttype", "staffPermission", "FirstName", "ParentInfo",
        "LastName", "selectedcourse", "messeging_token", "selectedCoursesName",
        "id2", "selectedStudentName", "selectedStudent", "memberOptions",
        "MyEditCourse", "MyEditModule", "SelectedLesson", "Data", "courseData",
        "students", "selectedButtons", "selectedStudentName", "selectedStudentId",
        "plannerCount", "selectedCourses", "CourseId", "ModulesID"
    ];

    itemsToRemove.forEach(item => localStorage.removeItem(item));

    Cookies.remove("token");
    Cookies.remove("Attendence");
    Cookies.remove("Accounttype");
    Cookies.remove("StaffId");


};
const clearAllCookies = () => {
    const allCookies = Cookies.get();
    for (let cookie in allCookies) {
        if (allCookies.hasOwnProperty(cookie)) {
            Cookies.remove(cookie);
        }
    }
};
export const User_Login_api = async (email, password) => {
    try {
        const response = await axios.post(CLIENT_API + "auth/login", {

            Email: encryption(email),
            Password: encryption(password),
        });
        //console.log(response);
        return response;
    } catch (error) {
        return error;
    }
};
export const User_email_existance = async (email, id) => {
    try {
        const response = await axios.get(CLIENT_API + `parentUserCheck?${email ? "Email=" + encryption(email) + "&" : ""}${"userID=" + id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const google_auth = async (access_token) => {
    try {
        const response = await axios.post(CLIENT_API + "auth/googleAuth", {
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`
            }
        });
        return response;
    } catch (error) {
        return error;
    }
};
export const facebook_signup = async (name, email, id) => {
    try {
        const response = await axios.post(CLIENT_API + "auth/facebookAuth", {
            fullName: name,
            email: email,
            fbId: id

        }, {
            headers: {
                'Content-Type': 'application/json',

            }
        });
        return response;
    } catch (error) {
        return error;
    }
};
export const google_signin = async (access_token) => {
    try {
        const response = await axios.get(CLIENT_API + "auth/signInGoogle", {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`
            }
        });
        return response;
    } catch (error) {
        return error;
    }
};
export const facebook_signin = async (email) => {
    try {
        const response = await axios.get(CLIENT_API + `auth/signInFacebook?${email ? "userEmail=" + email : ""}`, {
            headers: {
                'Content-Type': 'application/json',

            }
        });
        return response;
    } catch (error) {
        return error;
    }
};
export const signUp = async (email, otp) => {
    try {
        const response = await axios.post(CLIENT_API + `auth/otpCompare?${email ? "userEmail=" + email + "&" : null}${otp ? "Otp=" + otp + "&" : null}`, {
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const signInWithOtp = async (email, otp) => {
    try {
        const response = await axios.get(CLIENT_API + `auth/signinOTPCompare?${email ? "userEmail=" + email + "&" : null}${otp ? "Otp=" + otp + "&" : null}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const email_otp_generate = async (email) => {
    try {
        const response = await axios.get(CLIENT_API + `auth/signupMail?${email ? "userEmail=" + email : null}`, {
            headers: {

                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const signIn_otp_generate = async (email) => {
    try {
        const response = await axios.get(CLIENT_API + `auth/signInOtp?${email ? "userEmail=" + email : null}`, {
            headers: {

                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const user_signIn_process_status = async (email) => {
    try {
        const response = await axios.get(CLIENT_API + `auth/passwordStatus?${email ? "userEmail=" + email : null}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const parent_add_student = async (data, id) => {
    console.log(typeof ([data.Grade]));
    // const formattedAccountID = data.AccountID.toString().padStart(4, '0');
    try {
        const deviceToken = localStorage.getItem("messeging_token")
        const response = await axios.post(CLIENT_API + "student/", {

            Grade: data.Grade,
            // AccountType: data.AccountType,
            FirstName: data.Firstname,
            LastName: data.Lastname,
            // AccountID: formattedAccountID,
            Email: encryption(data.Email.toLowerCase()),
            Password: encryption(data.Password),
            PhoneNumber: data.Phonenumber,
            IsActive: 1,
            deviceToken: deviceToken,
            IsParticipateCompetitions: data.IsParticipateCompetitions,
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const profile_get_id = async () => {
    // console.log(data);
    ;
    try {
        const response = await axios.get(CLIENT_API + "profile", {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const parent_update = async (data, id) => {
    console.log(data);
    try {
        const requestData = {
            IsActive: "1",
            FirstName: data.FirstName,
            LastName: data.LastName,
            Email: encryption(data.Email.toLowerCase()),
            PhoneNumber: data.PhoneNumber,
            IsNoSuPeAchPoQu: data.IsNoSuPeAchPoQu,
            IsNCourseExNewsProm: data.IsNCourseExNewsProm,
            IsLookFeedback: data.IsLookFeedback,
        };

        // Check if data.Password exists, if yes, add it to the request data
        if (data.Password) {
            requestData.Password = encryption(data.Password);
        }
        const response = await axios.put(CLIENT_API + "profile/", requestData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const students_get = async () => {
    // console.log(data);
    ;
    try {
        const response = await axios.get(CLIENT_API + "student/?page=1&limit=100&", {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const parent_student_get_id = async (id) => {
    // console.log(data);
    ;
    try {
        const response = await axios.get(CLIENT_API + `student/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const parent_update_student = async (data, id) => {
    // const formattedAccountID = data.AccountID.toString().padStart(4, '0');
    console.log(data);
    try {
        const requestData = {
            Grade: data.Grade,
            // AccountType: data.AccountType,
            FirstName: data.FirstName,
            LastName: data.LastName,
            // AccountID: formattedAccountID, 
            Email: encryption(data.Email.toLowerCase()),
            PhoneNumber: data.PhoneNumber,
            IsActive: 1,
            IsParticipateCompetitions: data.IsParticipateCompetitions,
        };

        // Check if data.Password exists, if yes, add it to the request data
        if (data.Password) {
            requestData.Password = encryption(data.Password);
        }
        const response = await axios.put(CLIENT_API + `student/${id}`, requestData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const parent_student_delete = async (id) => {
    // console.log(data);
    try {
        const response = await axios.delete(CLIENT_API + `student/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const parent_help_get = async (page, category) => {
    console.log(category);
    try {
        const encodedCategory = category ? encodeURIComponent(category) : ""; // Encode category name
        const response = await axios.get(CLIENT_API + `help/?${page ? "page=" + page + "&" : ""}pageSize=10&${category && category !== "All" ? "Category=" + encodedCategory + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const parent_help_get_id = async (id) => {
    // console.log(data);
    try {
        const response = await axios.get(CLIENT_API + `help/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const parent_help_get_category = async () => {
    // console.log(data);
    try {
        const response = await axios.get(CLIENT_API + `help/category`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const parent_help_contact = async (data) => {
    try {
        const res = await axios.post(CLIENT_API + `contactus/`, {
            Email: data.Email,
            Message: data.Message,
            ContactType: data.ContactType
            // Learning_Advisor: data.Learning_Advisor,
            // Learning_Experience: data.Learning_Experience,
        }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return res

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const company_get_id = async () => {
    try {
        const response = await axios.get(CLIENT_API + `profile`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const company_update = async (data, id) => {
    console.log(data);
    try {
        const requestData = {
            IsActive: "1",
            FirstName: data.FirstName,
            LastName: data.LastName,
            Email: encryption(data.Email.toLowerCase()),
            PhoneNumber: data.PhoneNumber,
            TeamOrCompanyName: data.TeamOrCompanyName,
            TeamMember: data.TeamMember,
            IsNoSuPeAchPoQu: data.IsNoSuPeAchPoQu,
            IsNCourseExNewsProm: data.IsNCourseExNewsProm,
            IsLookFeedback: data.IsLookFeedback,
        };

        // Check if data.Password exists, if yes, add it to the request data
        if (data.Password) {
            requestData.Password = encryption(data.Password);
        }
        const response = await axios.put(CLIENT_API + "profile/", requestData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const company_help_get = async (page, category) => {
    console.log(category);
    try {
        const encodedCategory = category ? encodeURIComponent(category) : ""; // Encode category name
        const response = await axios.get(CLIENT_API + `help/?${page ? "page=" + page + "&" : ""}pageSize=10&${category && category !== "All" ? "Category=" + encodedCategory + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const company_help_get_category = async () => {
    // console.log(data);
    try {
        const response = await axios.get(CLIENT_API + `help/category`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const company_help_get_id = async (id) => {
    // console.log(data);
    try {
        const response = await axios.get(CLIENT_API + `help/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const company_help_contact = async (data) => {
    try {
        const res = await axios.post(CLIENT_API + `contactus/`, {
            Email: data.Email,
            Message: data.Message,
            ContactType: data.ContactType
            // Learning_Advisor: data.Learning_Advisor,
            // Learning_Experience: data.Learning_Experience,
        }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return res

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const Student_Event_get = async (grade, page) => {
    console.log(grade);
    console.log(page);
    try {
        const response = await axios.get(CLIENT_API + `event/?${page ? "page=" + page + "&" : ""}pageSize=5`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }

        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Student_submitted_Event_get = async (grade, page) => {
    try {
        const response = await axios.get(CLIENT_API + `event/get_submitted_events/aa?${page ? "page=" + page + "&" : ""}pageSize=5`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }

        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const submit_Event = async (data) => {

    try {
        const formData = new FormData();
        formData.append('CompetitionID', data.CompetitionID);
        formData.append('ImgFile', data.Thumbnail);

        const response = await axios.post(CLIENT_API + "event/event_Submit_entry", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token}`
            }
        });
        //console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};


export const add_planner = async (form) => {
    console.log(form);
    console.log("------------------type", typeof (JSON.stringify(form.Courses)), JSON.stringify(form.Lessons), JSON.stringify(form.Courses))
    // form.Courses = form.Courses.map(course => String(course));
    console.log("CourseIDs--------------", JSON.stringify(form.Courses))
    // form.Lessons = form.Lessons.map(lesson => String(lesson));

    try {
        const formData = new FormData();
        formData.append('StudentID', form.StudentID);
        if (form.Grade && form.KnowledgeCheck === 0) {
            formData.append('Grade', form.Grade);
        }
        formData.append('CourseIDs', JSON.stringify(form.Courses));
        formData.append('LessonIDs', JSON.stringify(form.Lessons));
        formData.append('Pathways', JSON.stringify(form.Pathways));
        formData.append('LearningStyle', form.LearningStyle);
        formData.append("IsCustomize", form.isCustomize)
        formData.append('KnowledgeCheck', form.KnowledgeCheck);
        formData.append('StartDate', form.StartDate.toISOString());
        formData.append('EndDate', form.EndDate.toISOString());
        formData.append('BreakTime', form?.BreakTime?.format('HH:mm'));
        formData.append('Monday', form?.Monday !== "" ? form?.Monday?.format('HH:mm') : null);
        formData.append('MondayEnd', form?.MondayEnd !== "" ? form?.MondayEnd?.format('HH:mm') : null);
        formData.append('Tuesday', form?.Tuesday !== "" ? form?.Tuesday?.format('HH:mm') : null);
        formData.append('TuesdayEnd', form?.TuesdayEnd !== "" ? form?.TuesdayEnd?.format('HH:mm') : null);
        formData.append('Wednesday', form?.Wednesday !== "" ? form?.Wednesday?.format('HH:mm') : null);
        formData.append('WednesdayEnd', form?.WednesdayEnd !== "" ? form?.WednesdayEnd?.format('HH:mm') : null);
        formData.append('Friday', form?.Friday !== "" ? form?.Friday?.format('HH:mm') : null);
        formData.append('FridayEnd', form?.FridayEnd !== "" ? form?.FridayEnd?.format('HH:mm') : null);
        formData.append('Thursday', form?.Thursday !== "" ? form?.Thursday?.format('HH:mm') : null);
        formData.append('ThursdayEnd', form?.ThursdayEnd !== "" ? form?.ThursdayEnd?.format('HH:mm') : null);
        formData.append('Saturday', form?.Saturday !== "" ? form?.Saturday?.format('HH:mm') : null);
        formData.append('SaturdayEnd', form?.SaturdayEnd !== "" ? form?.SaturdayEnd?.format('HH:mm') : null);
        formData.append('Sunday', form?.Sunday !== "" ? form?.Sunday?.format('HH:mm') : null);
        formData.append('SundayEnd', form?.SundayEnd !== "" ? form?.SundayEnd?.format('HH:mm') : null);
        formData.append('IsActive', 1);


        const response = await axios.post(CLIENT_API + "planner",
            formData
            , {
                headers: {
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    authorization: `Bearer ${token}`
                }
            });
        //console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Planner_get = async (startdate, enddate) => {
    try {
        const response = await axios.get(CLIENT_API + `planner/plan/getAllPlanner?startDate=${startdate ? startdate + "&" : ""}endDate=${enddate ? enddate + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}

export const Student_token = async (id) => {
    try {
        const res = await axios.get(CLIENT_API + `student/token/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return res

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }

}
export const courses_get_http = async (page, category, grade, selectedStudent2) => {
    console.log("grade in api ", grade)

    const gradeArray = grade === "All" ? undefined : grade !== "" ? grade?.split(",") : undefined;
    const gradesString = JSON.stringify(gradeArray);

    console.log("gradeArray", gradesString, "-----------------", encodeURIComponent(gradesString));
    try {
        const response = await axios.get(CLIENT_API + `course/grade/${selectedStudent2 ? selectedStudent2 + "/" : undefined + "/"}${encodeURIComponent(gradesString)}/${page ? page + "/" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const company_courses_get_http = async (page, category, grade, selectedStudent2) => {
    console.log("grade in api ", grade)
    const isGradeEmptyOrWhitespace = Array.isArray(grade) && grade.every(item => item.trim() === "");
    const gradeArray = grade[0] === "All" || isGradeEmptyOrWhitespace ? undefined : typeof grade === "string" ? grade.split(",") : grade;
    const gradesString = gradeArray ? JSON.stringify(gradeArray) : undefined;
    console.log("gradeArray", gradesString, "-----------------", encodeURIComponent(gradesString));
    try {
        const response = await axios.get(CLIENT_API + `course/grade/${selectedStudent2 ? selectedStudent2 + "/" : undefined + "/"}${encodeURIComponent(gradesString)}/${page ? page + "/" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const student_courses_get_http = async (page) => {
    console.log("token", token)
    try {
        const response = await axios.get(CLIENT_API + `course/courseDataStudent?${page ? "page=" + page + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const promo_get_http = async (id) => {
    try {
        const response = await axios.get(CLIENT_API + `promotion?AccountType=${id === "Company" ? 2 : null}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const course_detail_get_http = async (id) => {
    // console.log(data);
    try {
        const response = await axios.get(CLIENT_API + `course/courseFor/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const pathway_get_http = async (id) => {
    // console.log(data);
    try {
        const response = await axios.get(CLIENT_API + `pathway/?PathwayId=${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Planner_count_get = async (token) => {
    console.log("token in planner count", token)
    try {
        const response = await axios.get(CLIENT_API + `planner/count/flow`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const get_Portfolio_score = async (file) => {
    try {
        const formdata = new FormData();
        formdata.append('fileval', file)

        const response = await axios.post(CLIENT_API + 'portfolio/portfolioScore', formdata, {

            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token}`
            }
        })
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const invite_team = async (email) => {
    const formdata = new FormData();
    formdata.append("email", JSON.stringify(email))

    try {
        const response = await axios.post(CLIENT_API + `student/StudentInvite`, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const invited_student_get = async (search, status, page) => {
    // console.log(data);
    ;
    try {
        // const response = await axios.get(CLIENT_API + `Backend/Company/companys-get?${page ? "page=" + page + "&" : ""}pageSize=10&${search ? "Search=" + search + "&" : ""}${status && status !== "All" ? "Status=" + (status === "Active" ? 1 : 0) + "&" : ""}`, {
        const response = await axios.get(CLIENT_API + `student/StudentInvite/StudentInviteList?${page ? "page=" + page + "&" : ""}limit=10&${search ? "filter=" + search + "&" : ""}${status && status !== "All" ? "status=" + (status === "Active" ? 1 : 0) + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};


export const invited_student_get_by_id = async (id) => {
    // console.log(data);
    ;
    try {
        const response = await axios.get(CLIENT_API + `student/StudentInvite/StudentInviteList/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const invited_student_update = async (id, data) => {
    const requestData = {
        CompnayID: id,
        AccountType: data.AccountType,
        FirstName: data.Firstname,
        LastName: data.Lastname,
        TeamOrCompanyName: data.TeamOrCompanyName,
        TeamMember: data.TeamMember,
        Email: encryption(data.Email.toLowerCase()),
        PhoneNumber: data.Phonenumber,
        IsActive: 1,
        IsNCourseExNewsProm: data.IsNCourseExNewsProm,
        IsLookFeedback: data.IsLookFeedback,
    };
    try {
        const response = await axios.put(CLIENT_API + `student/StudentInvite/${id}`, requestData, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`
            }
        })
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}

export const invited_student_delete = async (id) => {
    // console.log(data);
    try {
        const response = await axios.delete(CLIENT_API + `student/StudentInvite/StudentInviteList/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};


export const get_membership_data = async () => {
    try {
        const response = await axios.get(CLIENT_API + `stripe/GetProduct`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }

}
export const get_subscribed_data = async (id) => {
    try {
        const response = await axios.get(CLIENT_API + `stripe/ActiveSubscription/Student/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }

}

export const create_membership = async (payment_method_key, payment_method_token, Product_id, Student_id, Parent_id) => {
    const formData = new FormData()
    formData.append("payment_method_key", payment_method_key)
    formData.append("payment_method_token", payment_method_token)
    formData.append("Product_id", Product_id)
    formData.append("Student_id", Student_id ? Student_id : 0)
    // if (Parent_id && Parent_id !== undefined) {
    formData.append("Parent_id", Parent_id);
    // }

    try {
        const response = await axios.post(CLIENT_API + `stripe/CreateSubscription`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${token}`
            }
        })
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const get_invoice_data = async () => {
    try {
        const response = await axios.get(CLIENT_API + `stripe/CreateInvoice`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }

}

export const student_reflection = async () => {
    try {
        const response = await axios.get(CLIENT_API + `student/reflections/aa`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        console.log("error", error, "status", error?.response?.data?.existStatus)
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const get_Achievement_badges = async () => {
    try {
        const response = await axios.get(CLIENT_API + `achievement/badges`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const certificates_get = async () => {
    try {
        const response = await axios.get(CLIENT_API + `achievement/certificate`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const portfolio_upload = async (portfolio, description, lessonID, moduleID, courseID, portfolioId, submissionStatus) => {
    console.log("data in api", portfolio, description)
    const formData = new FormData()
    if (submissionStatus === "resubmit") {

        formData.append('prevPort', JSON.stringify(portfolio));

    } else {
        for (let i = 0; i < portfolio.length; i++) {
            formData.append('Portfolio', portfolio[i]);
        }
    }


    formData.append("Description", description)
    formData.append("portfolioMainId", portfolioId)
    try {
        const response = await axios.post(CLIENT_API + `portfolio/portfolioAdd?${courseID ? "CourseId=" + courseID + "&" : ""}${moduleID ? "ModuleId=" + moduleID + "&" : ""}${lessonID ? "LessonId=" + lessonID + "&" : ""}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${token}`
            }
        })
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const portfolio_get = async () => {
    try {
        const response = await axios.get(CLIENT_API + `portfolio/portfolioData`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const get_portfolio_details = async (id) => {
    try {
        const response = await axios.get(CLIENT_API + `portfolio/portfolioDetail?${id ? "portfolioId=" + id : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }

}
export const get_notification = async () => {
    try {
        const response = await axios.get(CLIENT_API + `notify/notification`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const notification_seen = async (id) => {
    try {
        const response = await axios.post(CLIENT_API + `notify/notificationSeen?${id ? "notificationId=" + id : ""}`, {}, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }

}
export const add_company_parent_profile = async (data) => {
    console.log(data);
    try {
        const response = await axios.post(CLIENT_API + "parent/add/company", {
            // AccountType: data.AccountType,
            FirstName: data.Firstname,
            LastName: data.Lastname,
            Email: encryption(data.Email.toLowerCase()),
            Password: encryption(data.Password),
            PhoneNumber: data.Phonenumber,
            IsActive: 1,
            IsNoSuPeAchPoQu: data.IsNoSuPeAchPoQu,
            IsNCourseExNewsProm: data.IsNCourseExNewsProm,
            IsLookFeedback: data.IsLookFeedback,
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const invite_count = async () => {
    try {
        const response = await axios.get(CLIENT_API + `student/company/inviteCount`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const company_parent_getby_id = async (id) => {
    // console.log(data);
    ;
    try {
        const response = await axios.get(CLIENT_API + `parent/getParentData/company?${id ? "parentId=" + id : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const company_parent_update = async (data, id) => {
    console.log(data);
    try {
        const requestData = {
            IsActive: "1",
            FirstName: data.FirstName,
            LastName: data.LastName,
            Email: encryption(data.Email.toLowerCase()),
            PhoneNumber: data.PhoneNumber,
            IsNoSuPeAchPoQu: data.IsNoSuPeAchPoQu,
            IsNCourseExNewsProm: data.IsNCourseExNewsProm,
            IsLookFeedback: data.IsLookFeedback,
        };

        // Check if data.Password exists, if yes, add it to the request data
        if (data.Password) {
            requestData.Password = encryption(data.Password);
        }
        const response = await axios.put(CLIENT_API + `parent/updateParent/company?${id ? "parentId=" + id : ""}`, requestData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const company_parent_delete = async (id) => {
    // console.log(data);
    try {
        const response = await axios.delete(CLIENT_API + `parent/deleteParent/company?${id ? "parentId=" + id : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const get_company_parent_subscribed_data = async (id) => {
    try {
        const response = await axios.get(CLIENT_API + `subscription/parentSubscription?${id ? "parentId=" + id : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }

}
export const get_company_plan = async () => {
    try {
        const response = await axios.get(CLIENT_API + `stripe/GetProductCompany`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }

}
export const cancel_subscription = async (subscriptionId) => {

    try {
        const requestData = {
            sub_subscriptionid: subscriptionId,
            cancel_type: 0,
        };

        const response = await axios.post(CLIENT_API + `stripe/SubscriptionCancel`, requestData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const resend_invite = async (email) => {
    const formdata = new FormData();
    formdata.append("email", JSON.stringify(email))

    try {
        const response = await axios.post(CLIENT_API + `student/StudentInviteResend`, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};


export const subscription_status_check = async (id) => {
    try {
        const response = await axios.get(CLIENT_API + `subscription/parentSubscription?${id ? "studentId=" + id + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }

}
export const student_report = async (id) => {
    try {
        const response = await axios.get(CLIENT_ACTIVITY_API + `report/studentReport?${id ? "studentId=" + id : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }

}
export const report_overview = async () => {
    try {
        const response = await axios.get(CLIENT_ACTIVITY_API + `report/parentOverview`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }

}
export const parent_report = async () => {
    try {
        const response = await axios.put(CLIENT_ACTIVITY_API + `report/updateAttendance`, {}, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }

}


export const get_report = async (student, type, startDate, endDate) => {
    console.log("in api", student)
    try {
        const response = await axios.get(CLIENT_ACTIVITY_API + `report/filterReport?${student ? "studentId=" + student + "&" : ""}${type ? "filterType=" + type + "&" : ""}${startDate ? "startDate=" + startDate + "&" : ""}${endDate ? "endDate=" + endDate + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const admin_students_get = async () => {

    try {
        const response = await axios.get(CLIENT_API + "student/studentList/forAdmin", {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const subscription_get = async (id) => {
    try {
        const response = await axios.get(CLIENT_API + `subscription/subscriptionStatus`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }

}
export const subscription_status_get = async (id) => {
    try {
        const response = await axios.get(CLIENT_API + `subscription/subStatusParent`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }

}

export const student_time_schedule_check = async (data) => {
    try {
        const formData = new FormData()
        formData.append("StudentID", data.StudentID)
        formData.append("StartDate", data.StartDate)
        formData.append("weekData", JSON.stringify(data.weekData))
        const response = await axios.post(CLIENT_API + `planner/student/studentAvailability`, formData, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}

export const planner_get_by_id = async (studentId) => {
    try {
        const response = await axios.get(CLIENT_API + `planner/flowData/getFlowStatus?${studentId ? "studentId=" + studentId + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const update_planner = async (form, id) => {

    console.log(form);
    console.log("------------------type", typeof (JSON.stringify(form.Courses)), JSON.stringify(form.Lessons), JSON.stringify(form.Courses))
    console.log("CourseIDs--------------", JSON.stringify(form.Courses))
    console.log("plann upd")

    try {
        const formData = new FormData();
        formData.append('StudentID', form.StudentID);
        if (form.Grade && form.KnowledgeCheck === 0) {
            formData.append('Grade', form.Grade);
        }
        if (form.StartDate instanceof Date) {
            formData.append('StartDate', form.StartDate.toISOString());
        } else {
            formData.append('StartDate', form.StartDate);
        }
        if (form.EndDate instanceof Date) {
            formData.append('EndDate', form.EndDate.toISOString());
        } else {
            formData.append('EndDate', form.EndDate);
        }
        const lessonIDs = form.Lessons.map(Number);
        formData.append('CourseIDs', JSON.stringify(form.Courses));
        formData.append('LessonIDs', JSON.stringify(lessonIDs));
        formData.append('Pathways', JSON.stringify(form?.Pathways));
        formData.append('LearningStyle', form.LearningStyle);
        formData.append("IsCustomize", form.isCustomize)
        formData.append('KnowledgeCheck', form.KnowledgeCheck);

        if (form?.BreakTime?.format) {
            formData.append('BreakTime', form.BreakTime.format('HH:mm'));
        } else {
            formData.append('BreakTime', form.BreakTime);
        }
        formData.append('Monday', form?.Monday !== "" ? form?.Monday?.format('HH:mm') : null);
        formData.append('MondayEnd', form?.MondayEnd !== "" ? form?.MondayEnd?.format('HH:mm') : null);
        formData.append('Tuesday', form?.Tuesday !== "" ? form?.Tuesday?.format('HH:mm') : null);
        formData.append('TuesdayEnd', form?.TuesdayEnd !== "" ? form?.TuesdayEnd?.format('HH:mm') : null);
        formData.append('Wednesday', form?.Wednesday !== "" ? form?.Wednesday?.format('HH:mm') : null);
        formData.append('WednesdayEnd', form?.WednesdayEnd !== "" ? form?.WednesdayEnd?.format('HH:mm') : null);
        formData.append('Friday', form?.Friday !== "" ? form?.Friday?.format('HH:mm') : null);
        formData.append('FridayEnd', form?.FridayEnd !== "" ? form?.FridayEnd?.format('HH:mm') : null);
        formData.append('Thursday', form?.Thursday !== "" ? form?.Thursday?.format('HH:mm') : null);
        formData.append('ThursdayEnd', form?.ThursdayEnd !== "" ? form?.ThursdayEnd?.format('HH:mm') : null);
        formData.append('Saturday', form?.Saturday !== "" ? form?.Saturday?.format('HH:mm') : null);
        formData.append('SaturdayEnd', form?.SaturdayEnd !== "" ? form?.SaturdayEnd?.format('HH:mm') : null);
        formData.append('Sunday', form?.Sunday !== "" ? form?.Sunday?.format('HH:mm') : null);
        formData.append('SundayEnd', form?.SundayEnd !== "" ? form?.SundayEnd?.format('HH:mm') : null);
        formData.append('IsActive', 1);


        const response = await axios.put(CLIENT_API + `planner/edit/${id}`, formData, {
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                authorization: `Bearer ${token}`
            }
        });
        //console.log(response);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const get_all_portfolio_status = async (courseId, lessonId, portId) => {
    try {
        const response = await axios.get(CLIENT_API + `portfolio/portfolioStatus?${courseId ? "courseId=" + courseId + "&" : ""}${lessonId ? "lessonId=" + lessonId : ""}${portId ? "portfolioMainId=" + portId : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const Reset_yor_password = async (uniqueId, id, rn, password) => {
    try {
        const response = await axios.post(CLIENT_API + `student/reset/UserPasswordReset?${uniqueId ? "uuId=" + uniqueId + "&" : ""}${id ? "uId=" + id + "&" : ""}${rn ? "rn=" + rn : ""}`, { password: encryption(password) }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const configurePlan = async (courseId) => {
    try {
        const response = await axios.get(CLIENT_API + `planner/configurePlan/Student?${courseId ? "courseId=" + courseId + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}

export const generate_session_id = async (product, success_url, cancel_url, paymentMode) => {

    const data = {
        product: product,
        success_url: success_url,
        cancel_url: cancel_url,
        paymentMode: paymentMode
    }
    try {
        const response = await axios.post(CLIENT_API + `stripe/create-session-id`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
            }
        })
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const weekly_plan = async (date, day) => {
    try {
        const response = await axios.get(CLIENT_API + `planner/weeklyPlan/Student?${date ? "todayDate=" + date + "&" : ""}${day ? "todayDay=" + day + "&" : ""}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
            }
        })
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}
export const weekly_agenda_get = async (date) => {
    try {
        const response = await axios.get(CLIENT_API + `planner/weeklyPlanOverView/Student?${date ? "todayDate=" + date + "&" : ""}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
            }
        })
        return response;

    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
}


export const member_count = async (id) => {

    try {
        const response = await axios.get(CLIENT_API + `company/getEmpCount?${id ? "companyId=" + id + "&" : ""}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const getplanner_by_courseId = async (id) => {
    try {
        const response = await axios.get(CLIENT_API + `planner/coursePlannerDataByCourseId/Student?courseId=${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const Login_Reset_yor_password = async (email) => {
    try {
        const response = await axios.post(CLIENT_API + `profile/forgotPassword?userEmail=` + email, {}, {
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const get_all_products_stripe = async (email) => {
    try {
        const response = await axios.get(CLIENT_API + `stripe/getAllProductList`);
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};

export const Mark_Me_Interested = async (id, courseName, courseGrade) => {
    try {
        const response = await axios.post(ADMIN_API + `markMeInterested?courseID=${id}&courseName=${courseName}&courseGrade=${courseGrade}`,{}, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
export const Get_Me_Interested = async () => {
    try {
        const response = await axios.get(ADMIN_API + `getMeInterested`,{
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
            handleSignOut()
        } else if (error?.response?.data?.existStatus === 0) {
            handleSignOut()
        }
        return error;
    }
};
