import './App.css';
import { Routes1 } from './Routes';
import "./assets/admin/css/addCourse.css";
import "../src/components/course-grade-view/style.css";
import "../src/components/tooltip/customtooltip.css";
import "../src/components/user_access/user_access.css";
import "./assets/admin/css/dashboard.css";
import "./assets/admin/css/style.css";
import "./assets/company/css/style.css";
import "./assets/parent/css/style.css";
import "./assets/student/style.css"
import "./pages/student/parts/loaders/css/loader.css";
import "./pages/student/parts/loaders/css/eventloader.css";
import "./pages/student/parts/loaders/css/courseDetailloader.css";
import { listenForMessages, permision, requestPermission } from './Firebase/firebaseConfig';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
function App() {

  // Register the service worker for Firebase Messaging
  // main.js or wherever your main page JS is

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/firebase-messaging-sw.js')
//     .then((registration) => {
//       console.log('Service Worker registered with scope:', registration.scope);
//     })
//     .catch((error) => {
//       console.error('Service Worker registration failed:', error);
//     });

//   // Listen for messages from the service worker
//   navigator.serviceWorker.addEventListener('message', (event) => {
//     if (event.data && event.data.type === 'NOTIFICATION_RECEIVED') {
//       // Handle notification data or URL change
//       console.log('Notification received:', event.data);
      
//       // Set up visibility change listener
//       document.addEventListener('visibilitychange', () => {
//         if (!document.hidden) {
//           // Perform actions when the user returns to the tab
//           window.location.href = event.data.url;
//         }
//       });
//     }
//   });
// }



  // useEffect(() => {
  //   requestPermission();

  //   // Listen for foreground messages
  //   listenForMessages();
  // }, []);
  return (
    <>
      <Routes1 />
    </>
  );
}

export default App;
