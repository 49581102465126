import React, { useState , useEffect } from "react";
import Select, { components } from "react-select";
// import { ReactSelectCustomStyles } from './student/parts/selectStyle'
const ReactSelectCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: "8px 0px 8px 12px",
    borderRadius: "4px",
    border: state.isFocused
      ? "2px solid #8837f4"
      : "2px solid var(--Disabled-grey, #D7D7D7)",
    background: "#FFF",
    color: "var(--Primary-text, #424242)",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,

    height: "40px",

    lineHeight: "24px",
    boxShadow: "#8837f4",

    ":hover": {
      border: "2px solid #8837f4",
    },

    ":active": {
      border: "2px solid #8837f4",
    },
    minWidth: "170px",
    width:"100%"
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0px",
    margin: "0px",
    color: "#3E4958",
    border: "0px",
   

    position: "relative",
    top: "-7px",
  }),
  input: (provided) => ({
    ...provided,
    color: "#3E4958",
  }),
  
  option: (state) => ({
    padding: "8px 12px",
    fontSize: "16px",
    fontWeight: 500,
    fontStyle: "roboto",
    lineHeight: "24px",
    outLine: "none",
    cursor: "pointer",
    boxShadow: "none",
    color: state.isSelected ? "#462078" : "#424242", // Text color when selected
    background: state.isSelected ? "#D9CDE9" : "transparent",
    ":last-child": { borderBottom: "0" },
    ":hover": { color: "#8837f4" },
    ":active": { color: "#462078" ,background: "#D9CDE9" },
    scrollbarColor: "#737373",
    
  }),
  singleValue: (base) => ({
    ...base,
    padding: 5,
    borderRadius: 5,
    color: "#424242",
    display: "flex",
  }),
  menuList: (base) => ({
    ...base,
   
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
      
      borderRadius: "5px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      marginTop : "6px",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#737373",  
      borderRadius:"4px"
    },
  }),
  getStyles: (provided, state) => ({
    ...provided,
    // Your custom styles for main components here
  }),
};

const CustomOption = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isSelected, setIsSelected] = useState(false);


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

    return (
      <components.Option {...props}>
    {console.log()}
        {!props.data._1st && props.data.part &&  <hr className="m-0 mb-3"/>}
        <div
          className="d-flex gap-2"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
       
          style={{ paddingTop: props.isFirstOption ? '0' : '0' }}
        >
          {props.data.icon && (
            <img
              src={isHovered ? props.data.hoverIcon : props.data.icon}
              alt={props.data.label}
              className="option-icon"
            />
          )}
          <li
            className={`custom-option ${isHovered ? "semi_violet_text" : ""}  ${isSelected ? "violet_text" : ""}`}
          >{props.data.part ? (<>
              <span className={`select_title`}>{props.data.label}</span>
          </>): (props.data.label)}
          </li>
        </div>
      </components.Option>
    );
  }

const CustomSingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    {props.data.icon && <img src={props.data.icon} alt={children} className="value-icon me-2" />}
     {children}
  </components.SingleValue>
);
const SelectBox = ({
  options,
  placeholder,
  name,
  id,
  defaultVal,
  isSearchable,
  onChange,
  isMultiSelect,
  aria_labelledby, className, disable
}) => {
  // console.log(defaultVal);
  // console.log(options);
  // const firstOptionWithIcon = options.find(option => option.icon);
  const [defaultValue, setDefaultValue] = useState();
  const [defaultValue2, setDefaultValue2] = useState({ value: 'Parent', label: 'Parent', icon: '/img/Group_grey.svg', hoverIcon: '/img/Group122.svg' });

  useEffect(() => {
    if (defaultVal) {

      const firstOptionWithValue = options.find(option => option.value == defaultVal);
      setDefaultValue(firstOptionWithValue);
      console.log(firstOptionWithValue);
    }
  }, [defaultVal, options]);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    console.log(defaultValue)
  }, [defaultValue]);

  useEffect(() => {
    // Delay setting the defaultValue by 2 seconds
    const timeoutId = setTimeout(() => {
      setIsLoaded(true);
    }, 1000); // 2 seconds delay

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []);
  if (!isLoaded) {
    return null; // Render nothing until loaded
  }
  return (
    <Select
      options={options}
      styles={ReactSelectCustomStyles}
      placeholder={placeholder}
      onChange={onChange}
      isMulti={isMultiSelect}
      // menuIsOpen={true}  
      defaultValue={defaultValue}
      id={id}
      isDisabled={disable}
      name={name}
      aria-labelledby={aria_labelledby}
      isSearchable={isSearchable === true  ? true : false}
      // components={{ Option: CustomOption }}
      // components={{
      //   Option: (props) => <CustomOption {...props} isFirstOption={props.data === options[0]} />
      // }}
      components={{
        Option: (props) => <CustomOption {...props} isFirstOption={props.data === options[0]} />,
        SingleValue: CustomSingleValue
      }}
      // components={{ SingleValue: CustomSingleValue }}

      className={"select_dropdown " + className}
    />
  );
};

export default SelectBox;
