import React, { useEffect, useState } from "react";
import { Headline } from "../headline/headline.js";
import { Adminsidebar } from "../adminsidebar.js";
import { Footer } from "../footer/footer.js";
import { Parent_notification } from "../../pages/parent/popup/Parent_notification.js";
import { useParams } from "react-router-dom";
import { company_get_id, user_get_id } from "../../service/api.js";
export const AdminLayout = ({ children, headline }) => {
  const [fName, setFName] = useState("")
  const [LName, setLName] = useState("")
  useEffect(() => {
    // getuser()
    setFName(localStorage.getItem("FirstName"))
    setLName(localStorage.getItem("LastName"))
  }, [])
  // const userId = localStorage.getItem("StaffId")
  //   const getuser = async ( )=> {
  //     const res = await company_get_id(userId)
  //     setFName(res.data.user.FirstName)
  //     setLName(res.data.user.LastName)

  //     console.log(res)
  //   }
  const { id } = useParams()
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 980) {
        // setMobileMenuOpen(true);
      } else {
        setMobileMenuOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const _nav = [
    {
      name: "Dashboard",
      to: "/company_dashboard",
      icon: "/img/sidebar/1.svg",
    },
    {
      name: "Catalog",
      to: "/course_catalog",
      icon: "/img/sidebar/2.svg",
    },
    {
      hr: true,
    },
    {
      name: "Account",
      and: ["/company_member_add",`/company_member_add/${id}`],
      to: "/company_account",
      icon: "/img/sidebar/9.svg",
    },
    {
      name: "Team",
      and: ["/company_invite", `/company_profile/${id}/`],
      to: "/company_profile_list_table",
      icon: "/img/sidebar/6.svg",
    },
    {
      name: "Membership",
      to: "/company_membership",
      icon: "/img/sidebar/parent/6.svg",
    },
    {
      name: "Billing & invoice",
      to: "/company_billing",
      icon: "/img/sidebar/parent/7.svg",
    },
    {
      name: "Support",
      and: [`/company_answer/${id}`, "/company_contact", "/company_contact_learning_advisor", "/company_contact_representative", "/company_leave_feedback"],
      to: "/company_help",
      icon: "/img/sidebar/parent/8.svg",
    },
    {
      name: "Sign out",
      to: "Js:",
      icon: "/img/sidebar/8.svg",
    },
  ];
  return (
    <>
      {/* {headline === true && (
        <Headline className={`yellow-bg ${isMobileMenuOpen ? "d-none" : ""
          }`}>Refer a friend and get 1 month free! ✅</Headline>
      )} */}
      <div
        className={`admin_layout_wrapper container-fluid ${isMobileMenuOpen ? "mobile-menu-open" : ""
          }`}
      >
        <header className="admin_layout_head">
          {isMobileMenuOpen ? (
            <div className="mobile_menu_call_btn" onClick={toggleMobileMenu}>
              <img src="/img/Close1.svg" alt="Close" />
            </div>
          ) : (
            <div className="mobile_menu_call_btn" onClick={toggleMobileMenu}>
              <img src="/img/Hamburger.png" alt="Hamburger" />
            </div>
          )}

          <div className="admin_layout_logo">
            <img src="/img/platform_logo.svg" alt="EDBITION" />
          </div>
          <div className="admin_layout_head2">
            <div className="admin_layout_pagename"></div>
            <div className="admin_layout_icons">
              <Parent_notification />
              <div className="admin_layout_user">{fName.charAt(0).toUpperCase() + LName.charAt(0).toUpperCase()}</div>
            </div>
          </div>
        </header>
        <div className="addminFull"></div>
        <div className="admin_layout_main">
          <aside className="admin_layout_sidebar">
            <Adminsidebar
              item={_nav}
              isMobileMenuOpen={isMobileMenuOpen}
              toggleMobileMenu={toggleMobileMenu}
            />
          </aside>
          <div className="admin_layout_body">
            <div className="admin_layout_breadcrumbs">
              <p></p>
            </div>
            {children}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
