import React from 'react';
import CryptoJS from 'crypto-js';

const secretKey = 'bco0njJ3OTzunxNxXShOqsQImCyvagKP';


export const encryption = (str) => {
    return CryptoJS.AES.encrypt(str, secretKey).toString();
}
export const decryption = (str) => {
    return CryptoJS.AES.decrypt(str, secretKey).toString(CryptoJS.enc.Utf8);
}


 